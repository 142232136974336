import { combineReducers } from 'redux';
import { components } from '@peachjar/components';
import { reducer as flagsReducer } from './flags/flags.duck';

const { Notifications } = components;
const { notificationsReducer } = Notifications;

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  flags: flagsReducer
});

export default rootReducer;
