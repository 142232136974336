import { useQuery } from '@apollo/react-hooks'
import React, { FC } from 'react'
import GET_USER from '../../components/App/App.graphql'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import UserContext from './UserContext'

const UserProvider: FC = ({ children }) => {
  const {
    loading: userLoading,
    data: userData,
    refetch: refetchUser
  } = useQuery(GET_USER, {
    fetchPolicy: 'network-only'
  })

  if (userLoading) { return <LoadingSpinner /> }

  return (
    <UserContext.Provider value={{ userData, refetchUser }}>
      { children }
    </UserContext.Provider>
  )
}

export default UserProvider
