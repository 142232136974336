import { css } from 'emotion'
import { colors } from '@peachjar/components'

const styles = {
  app: css`
    height: 100vh;
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    background-color: ${colors.platinum};
  `,
  columnContainer: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
  `,
  contentWrapper: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    overflow: auto;
  `,
  subapps: css`
    height: auto;
    padding: 0 15px 4rem 15px;
    width: 100%;
  `,
}

export default styles

