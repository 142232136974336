import { Flags } from '../../../utils/flags'

type FlagsInitialState = Flags & {
  initialized: boolean;
}

const SET_FEATURE_FLAGS = 'flags/SET_FEATURE_FLAGS'

const initialState: FlagsInitialState = {
  initialized: false,
  portal_nav: 0,
  portal_nav_banner: 0,
  upload_flyer: 0,
  approval_center: 0,
  approval_center_banner: 0,
  org_uploader: 0,
  managed_services: 0,
}

export function setReduxFeatureFlags(flags: FlagsInitialState) {
  return async dispatch => {
    dispatch({
      type: SET_FEATURE_FLAGS,
      payload: flags,
    })
  }
}

export function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_FEATURE_FLAGS:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}
