import React from 'react'

const SendFlyerIcon = props => (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" { ...props }>
      <title>baseline-all_inbox-24px</title>
      <g id="School-Portal---Laura" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Flyer-Report" transform="translate(-18.000000, -179.000000)">
              <g id="Nav-Bar-/-Expanded-Copy" transform="translate(0.000000, 56.000000)">
                  <g id="nav---text" transform="translate(0.000000, 8.000000)">
                      <g id="nav-item---distribution" transform="translate(16.000000, 65.000000)">
                          <g id="baseline-all_inbox-24px" transform="translate(0.000000, 48.000000)">
                              <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
                              <path d="M12.6666667,2 L3.33333333,2 C2.6,2 2,2.6 2,3.33333333 L2,8 C2,8.73333333 2.6,9.33333333 3.33333333,9.33333333 L12.6666667,9.33333333 C13.4,9.33333333 14,8.73333333 14,8 L14,3.33333333 C14,2.6 13.4,2 12.6666667,2 Z M12.6666667,6 L10,6 C10,7.08 9.08,8 8,8 C6.92,8 6,7.08 6,6 L3.33333333,6 L3.33333333,3.33333333 L12.6666667,3.33333333 L12.6666667,6 Z M10,10.6666667 L14,10.6666667 L14,12.6666667 C14,13.4 13.4,14 12.6666667,14 L3.33333333,14 C2.6,14 2,13.4 2,12.6666667 L2,10.6666667 L6,10.6666667 C6,11.7733333 6.89333333,12.6666667 8,12.6666667 C9.10666667,12.6666667 10,11.7733333 10,10.6666667 Z" id="Shape" fill="#2D344B" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
)

export default SendFlyerIcon
