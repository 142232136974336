import axios from 'axios';
import { delay } from 'redux-saga/effects';
import { call } from 'redux-saga/effects';
import config from '../../config/config';

const API_BASE_URL = config.REACT_APP_API_BASE_URL;

axios.defaults.baseURL = API_BASE_URL;

export const FETCH = 'FETCH';

export function fetchEndpoint({ url, data }) {
  return {
    type: FETCH,
    url,
    data,
  };
}

export function* fetch(action) {
  for (let i = 0; i < 5; i++) {
    try {
      const { url, data } = action;
      const result = yield call(axios, {
        method: action.method || 'post',
        url,
        data,
      });
      return result;
    } catch (err) {
      if (i < 4) {
        yield call(delay, 2000);
      }
    }
  }
  // attempts failed after 5 attempts
  throw new Error('API request failed');
}
