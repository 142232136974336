// @ts-nocheck
import React from 'react'
import { css } from 'emotion'
import { connect } from 'react-redux'
import SidebarDrawer from '../SidebarDrawer/SidebarDrawer'
import SidebarDrawerNavItem from '../SidebarDrawer/SidebarDrawerNavItem'
import SidebarFull from '../Sidebar/SidebarFull'
import SidebarNavItem from '../SidebarNavItem/SidebarNavItem'
import SidebarNavSection from '../SidebarNavSection/SidebarNavSection'
import getSideNavConfig from '../../config/sideNavConfig'
import RoleCheck from '../../utils/RoleCheck'

import { SIDEBAR_WIDTH } from '../../config/constants'
import { Flags } from '../../utils/flags'

type Props = {
  permissions: {
    uploaderLevel: string
    adminLevel: string
    approverLevel: string
  }
  flags: Flags
}

const Sidebar: React.FunctionComponent<Props> = ({ flags, permissions }) => {
  const { portal_nav } = flags
  const { sideNavSections } = getSideNavConfig(permissions)

  const getSidebarConfig = () => {
    const isPortalNavEnabled = portal_nav === 1
    return {
      Sidebar: isPortalNavEnabled ? SidebarDrawer : SidebarFull,
      SidebarNavItem: isPortalNavEnabled ? SidebarDrawerNavItem : SidebarNavItem,
      SidebarNavSection
    }
  }

  const SidebarConfig = getSidebarConfig()

  return (
    <div className={classNames.sidebar}>
      <SidebarConfig.Sidebar>
        <SidebarConfig.SidebarNavSection label="">
          {sideNavSections
            .filter(({ showInSideNav }) => showInSideNav)
            .map(
              ({
                label,
                path,
                externalLink,
                BadgeComponent,
                allowedRoles,
                exact
              }) => {
                const Badge = BadgeComponent ? <BadgeComponent /> : null
                return (
                  <RoleCheck roles={allowedRoles} key={label}>
                    <SidebarConfig.SidebarNavItem
                      path={path}
                      label={label}
                      externalLink={externalLink}
                      exact={exact}
                      badge={Badge}
                    />
                  </RoleCheck>
                )
              }
            )}
        </SidebarConfig.SidebarNavSection>
      </SidebarConfig.Sidebar>
    </div>
  );
}

const classNames = {
  sidebar: css`
    width: ${SIDEBAR_WIDTH.sm};

    @media screen and (min-width: 1380px) {
      width: ${SIDEBAR_WIDTH.md};
    }
  `
}

export default connect(
  state => ({ flags: state.flags }),
  null
)(Sidebar)
