import { ButtonPrimarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons';
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications';
import gql from 'graphql-tag';
import React, { useContext, useEffect } from 'react';
import config from '../../config/config';
import portalBFFClient from '../../middleware/apollo/apolloBffClient';
import UserContext from '../../middleware/context/UserContext';

const DISTRIBUTION_SETTINGS = gql`
  query getDistributionSettings($districtId: String!) {
    distributionScheduleSettings(
      input: { hierarchyType: "district", hierarchyId: $districtId }
    ) {
      frequency
      days
      timeHour
      timeMin
    }
  }
`;

const distributionSettingsUrl = `${config.REACT_APP_PORTAL_BFF_URL}/settings/distribution`

async function queryApprovalSettingsDraft(districtId: string) {
  const { data } = await portalBFFClient.query({
    query: DISTRIBUTION_SETTINGS,
    fetchPolicy: 'no-cache',
    variables: {
      districtId,
    },
  })
  return data?.distributionScheduleSettings.hasBeenSaved ?? true
}

const DistributionSettingsNotification = () => {
  const { notifyWarning } = useGlobalNotifications()
  const { userData } = useContext(UserContext)
  
  const districtId = userData?.profile.hierarchy.id
  const isDistrictAdmin = userData?.profile.permission.adminLevel === 'district'
  
  useEffect(() => {
    if (isDistrictAdmin && window.location.href !== distributionSettingsUrl) {
      queryApprovalSettingsDraft(districtId).then(hasBeenSaved => {
        if (!hasBeenSaved) {
          notifyWarning({
            message: 'There is a new setting that impacts the schedule of your flyers. Please go to the distribution setting page to set this setting.',
            action: (
              <ButtonPrimarySmall
                href={distributionSettingsUrl}
              >
                Go to Distribution Setting
              </ButtonPrimarySmall>
            )
          })
        }
      })
    }
  }, [districtId, isDistrictAdmin, notifyWarning])

  return <></>
}

export default DistributionSettingsNotification
