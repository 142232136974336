import React from 'react'

const DistributionIcon = props => (
  <svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>icon - distribution</title>
    <desc>Created with Sketch.</desc>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Nav-Bar-/-Collapsed" transform="translate(-23.000000, -74.000000)">
            <g id="Group" transform="translate(8.000000, 24.000000)">
                <g id="icon---distribution" transform="translate(13.000000, 49.000000)">
                    <polygon id="Shape" points="0 0 15.3372781 0 15.3372781 16 0 16"></polygon>
                    <path d="M9.53575,1 L4.20075,1 C3.4671875,1 2.87366875,1.585 2.87366875,2.3 L2.867,12.7 C2.867,13.415 3.46051875,14 4.19408125,14 L12.20325,14 C12.9368125,14 13.537,13.415 13.537,12.7 L13.537,4.9 L9.53575,1 Z M10.8695,10.1 L8.868875,10.1 L8.868875,12.05 L7.535125,12.05 L7.535125,10.1 L5.5345,10.1 L5.5345,8.8 L7.535125,8.8 L7.535125,6.85 L8.868875,6.85 L8.868875,8.8 L10.8695,8.8 L10.8695,10.1 Z M8.868875,5.55 L8.868875,1.975 L12.5366875,5.55 L8.868875,5.55 Z" id="Shape" fill="#2D344B" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default DistributionIcon
