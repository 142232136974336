import * as React from 'react'
import { css } from 'emotion'
import { BREAKPOINTS } from '@peachjar/ui/dist/constants'
import { colors, components } from '@peachjar/components'
import { SIDEBAR_WIDTH } from '../../config/constants'

const { VerticalNav } = components

type Props = {
  children: React.ReactNode,
}

const Sidebar = ({ children }: Props) => (
  <div className={classNames.sidebar}>
    <div className={classNames.sidebarNav}>
      <VerticalNav label="Main Site Navigation">{children}</VerticalNav>
    </div>
  </div>
)

const classNames = {
  sidebar: css`
    display: flex;
    flex-flow: column nowrap;
    min-width: ${SIDEBAR_WIDTH.sm};
    max-width: ${SIDEBAR_WIDTH.sm};
    padding: 0.5rem;
    padding-top: 1.5rem;
    border-right: 1px solid ${colors.mercury};
    overflow: auto;

  `,
  sidebarNav: css`
    flex: 0 0 auto;
  `,
  sidebarContent: css`
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
  `,
  noteBox: css`
    line-height: 1.23;
    padding: 1rem 1rem 1rem 0.5rem;
    margin: 1rem 0.2rem;
    border-radius: 3px;
    background-color: #ffe6aa;

    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      padding: 1rem;
      margin: 1rem 0.5rem;
    }
  `,
  divider: css`
    margin-top: 0;
    margin-bottom: 0;
    width: 90%;
  `,
  backArrow: css`
    padding-right: 4px;
    vertical-align: bottom;
  `,
}

export default Sidebar
