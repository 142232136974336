import React from 'react'

const DistributionScheduleIcon = props => (
<svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M13.8 1.6H13V0H11.4V1.6H5V0H3.4V1.6H2.6C1.712 1.6 1 2.32 1 3.2V14.4C1 15.28 1.712 16 2.6 16H13.8C14.68 16 15.4 15.28 15.4 14.4V3.2C15.4 2.32 14.68 1.6 13.8 1.6ZM13.8 14.4H2.6V6.4H13.8V14.4ZM13.8 4.8H2.6V3.2H13.8V4.8ZM4.2 8H8.2V12H4.2V8Z" fill="#2D344B"/>
</svg>
)

export default DistributionScheduleIcon

