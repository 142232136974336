// import { redirect } from '../_app/redirect'
import config from '../config/config'
import { LOG_OUT_LOCAL_STORAGE_KEYS } from '../config/constants'

const { REACT_APP_LOGOUT_URL, REACT_APP_ACCOUNTS_APP_URL } = config

interface ILogOutStorage {
  keys: string[]
}

export const clearLocalStorageOnLogout = (storageEnum: ILogOutStorage ): void => {
  const { keys } = storageEnum

  for (const elem of keys) {
    if (localStorage.getItem(elem)) {
      localStorage.removeItem(elem)
    }
  }
}

// redirectUrl is the location to redirect to once the user has been effectively logged out.
const logout = (redirectUrl: string = `${REACT_APP_ACCOUNTS_APP_URL}/login`): void => {
  clearLocalStorageOnLogout(LOG_OUT_LOCAL_STORAGE_KEYS)
  const logoutUrl = `${REACT_APP_LOGOUT_URL}?redirect=${encodeURIComponent(redirectUrl)}`
  window.location.href = logoutUrl
}

export default logout
