import React from 'react'

const ApprovalHistoryIcon = props => (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" { ...props }>
    <title>outline-list_alt-24px</title>
    <g id="School-Portal---Laura" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Flyer-Report" transform="translate(-18.000000, -324.000000)">
            <g id="Nav-Bar-/-Expanded-Copy" transform="translate(0.000000, 56.000000)">
                <g id="nav---text" transform="translate(0.000000, 8.000000)">
                    <g id="nav-item---approvals" transform="translate(16.000000, 162.000000)">
                        <g id="outline-list_alt-24px" transform="translate(0.000000, 96.000000)">
                            <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
                            <path d="M7.33333333,4.66666667 L11.3333333,4.66666667 L11.3333333,6 L7.33333333,6 L7.33333333,4.66666667 Z M7.33333333,7.33333333 L11.3333333,7.33333333 L11.3333333,8.66666667 L7.33333333,8.66666667 L7.33333333,7.33333333 Z M7.33333333,10 L11.3333333,10 L11.3333333,11.3333333 L7.33333333,11.3333333 L7.33333333,10 Z M4.66666667,4.66666667 L6,4.66666667 L6,6 L4.66666667,6 L4.66666667,4.66666667 Z M4.66666667,7.33333333 L6,7.33333333 L6,8.66666667 L4.66666667,8.66666667 L4.66666667,7.33333333 Z M4.66666667,10 L6,10 L6,11.3333333 L4.66666667,11.3333333 L4.66666667,10 Z M13.4,2 L2.6,2 C2.26666667,2 2,2.26666667 2,2.6 L2,13.4 C2,13.6666667 2.26666667,14 2.6,14 L13.4,14 C13.6666667,14 14,13.6666667 14,13.4 L14,2.6 C14,2.26666667 13.6666667,2 13.4,2 L13.4,2 Z M12.6666667,12.6666667 L3.33333333,12.6666667 L3.33333333,3.33333333 L12.6666667,3.33333333 L12.6666667,12.6666667 Z" id="Shape" fill="#2D344B" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default ApprovalHistoryIcon
