import { colors } from '@peachjar/components'
import { css, cx } from 'emotion'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { matchPath } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'
import VerticalNavAnchor from './VerticalNavAnchor'

type Props = {
  label: string
  path: string
  externalLink: boolean
  exact: boolean
  children: ReactNode
  'data-testid'?: string
  className?: string
}

const sameRouteBase = /(?:https:\/\/.+?)?(?<basePath>\/\w+)(?:\/.+)?/
const exactRoute = /(?:https:\/\/.+?)?(?<basePath>\/.+)/
const isNavItemActive = (path: string, currentPath: string, exact: boolean) => {
  const match = exact
    ? exactRoute.exec(path)
    : sameRouteBase.exec(path)

  return match && Boolean(
    matchPath(
      currentPath,
      { path: match.groups?.basePath, exact }
    )
  )
}

const VerticalNavItem = ({
  label,
  path,
  externalLink,
  exact = false,
  children,
  className = "",
  "data-testid": testId
}: Props) => {
  const pathname = usePathname()

  const isActive = useMemo(
    () => isNavItemActive(path, pathname, exact),
    [path, exact, pathname]
  )

  const otherProps = externalLink ? {} : { onClick: navigateToUrl }

  return (
    <li
      aria-label={label}
      key={label}
      className={cx(
        classNames.liReset,
        classNames.defaultState,
        isActive && classNames.activeState,
        className
      )}
      data-testid={testId}
    >
      <VerticalNavAnchor path={path} { ...otherProps }>{children}</VerticalNavAnchor>
    </li>
  )
}

VerticalNavItem.defaultProps = {
  className: '',
}

const classNames = {
  liReset: css`
    list-style-type: none;
  `,
  defaultState: css`
    border-radius: 3px;
    border-left: 4px solid transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  `,
  activeState: css`
    border-left: 4px solid ${colors.leaf};
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.03);
  `,
}

// @ts-ignore
export default VerticalNavItem

function usePathname(): string {
  const [pathname, setPathname] = useState(window.location.pathname)
  
  const onRouteChange = () => {
    setPathname(window.location.pathname)
  }
  
  useEffect(() => {
    window.addEventListener('single-spa:app-change', onRouteChange)
    window.addEventListener('popstate', onRouteChange)
    return () => {
      window.removeEventListener("single-spa:app-change", onRouteChange)
      window.removeEventListener("popstate", onRouteChange)
    }
  }, [])

  return pathname
}