
import * as React from 'react'
import { css } from 'emotion'
import { elements } from '@peachjar/components'
import VerticalNavItem from '../VerticalNav/VerticalNavItem'

const { Note } = elements.typography

type Props = {
  path: string
  label: string
  externalLink: boolean
  exact: boolean
  badge?: React.ReactNode
}

const SidebarItem = ({
  path,
  label,
  externalLink,
  exact = false,
  badge = null
}: Props) => (
  <VerticalNavItem
    path={path}
    label={label}
    externalLink={externalLink}
    exact={exact}
  >
    <Note className={classNames.spaceBetween}>
      {label}
      {badge}
    </Note>
  </VerticalNavItem>
)

const classNames = {
  spaceBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
}

export default SidebarItem
