import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import portalBFFClient from '../../middleware/apollo/apolloBffClient'
import config from '../../config/config'

const DRAFT_QUERY = gql`
  query ApprovalSettingsDraftQuery {
    getApprovalSettings {
      isDraft
    }
  }
`

const approvalSettingsUrl = `${config.REACT_APP_PORTAL_BFF_URL}/settings/approvals`

async function queryApprovalSettingsDraft() {
  const { data } = await portalBFFClient.query({
    query: DRAFT_QUERY,
    fetchPolicy: 'no-cache',
  })
  return data.getApprovalSettings?.isDraft
}

const DraftNotification = () => {
  const { notifyWarning } = useGlobalNotifications()
  
  useEffect(() => {
    if (window.location.href !== approvalSettingsUrl) {
      queryApprovalSettingsDraft().then(isDraft => {
        if (isDraft) {
          notifyWarning({
            message: 'You have unsaved Approval Settings changes. Go to Approval Settings to review and activate your workflow.',
            action: (
              <ButtonSecondarySmall
                href={approvalSettingsUrl}
              >
                Go to Approval Settings
              </ButtonSecondarySmall>
            )
          })
        }
      })
    }
  }, [notifyWarning])

  return <></>
}

export default DraftNotification
