import ApolloClient from "apollo-boost"
import config from "../../config/config"
import handleGqlAuthError, { isGqlAuthError } from '../../utils/handleGqlAuthError'

const GRAPHQL_URI = config.BFF_ACCOUNTS_GRAPHQL_URI

function createApolloClient({ initialState = {} }) {
  return new ApolloClient({
    uri: GRAPHQL_URI,
    credentials: "include",
    onError: ({ networkError, graphQLErrors }) => {
      // @ts-ignore
      if (graphQLErrors && isGqlAuthError(graphQLErrors)) {
        handleGqlAuthError(`${document.title} error: user is not authenticated`)
      }
      console.log("graphQLErrors", graphQLErrors)
      console.log("networkError", networkError)
    }
  })
}

const accountsBffClient = createApolloClient({})

export default accountsBffClient
