import { createMuiTheme } from '@material-ui/core/styles/index'
import { neutral, primary } from '../config/colors'

const contentTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#576282',
      main: '#2D344A',
      dark: primary.prussian,
      contrastText: neutral.white,
    },
    secondary: {
      light: '#6ABF6F',
      main: '#4BA24D',
      dark: primary.jungle,
      contrastText: neutral.white,
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: "'Proxima Nova', Helvetica, sans-serif",
    h4: {
      fontSize: 25,
      fontWeight: 600,
      color: '#2D344A',
    },
  },
  root: {
    '& $TabIndicator': {
      height: '4px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#f5f5f5',
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: '4px 24px 4px 24px;'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiButton: {
      root: {
        fontSize: 13,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 16,
      },
      // '[class^="SimpleSelect-formControl"]': {
      //   minWidth: 180,
      // },
    },
  },
});

export default contentTheme;
