import getFeatureFlags from '../utils/flags'

const RolesEnum = {
  SCHOOL_ADMIN: 'SCHOOL_ADMIN',
  DISTRICT_ADMIN: 'DISTRICT_ADMIN',
  SCHOOL_APPROVER: 'SCHOOL_APPROVER',
  DISTRICT_APPROVER: 'DISTRICT_APPROVER',
  SCHOOL_UPLOADER: 'SCHOOL_UPLOADER',
  DISTRICT_UPLOADER: 'DISTRICT_UPLOADER',
  ORG: 'ORG',
  COMMUNITY_FREE: 'COMMUNITY_FREE',
}

export const pendingApprovalsAllowedRoles = [
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
]

export const myApprovalsAllowedRoles = [
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
]

export const allApprovalsAllowedRoles = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
]

export const approvalsAllowedRoles = [
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.DISTRICT_APPROVER,
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
]

const campaignAllowedRolesAsOrg = [
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
]

const campaignAllowedRolesDefault = [
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.SCHOOL_UPLOADER,
]

export const campaignsAllowedRoles = getCampaignRoles()

export const reportingAllowedRolesAsOrg = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
];

export const reportingAllowedRolesDefault = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.DISTRICT_UPLOADER,
];

export const reportingAllowedRoles = getReportingAllowedRoles()

export const dealsAllowedRoles = [RolesEnum.ORG, RolesEnum.COMMUNITY_FREE]

export const transactionsAllowedRoles = [
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
]

export const helpAllowedRoles = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.DISTRICT_APPROVER,
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
]

export const accountInfoAllowedRoles = [
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.DISTRICT_APPROVER,
  RolesEnum.SCHOOL_APPROVER,
  RolesEnum.ORG,
  RolesEnum.COMMUNITY_FREE,
]

export const distributionAllowedRoles = [
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.DISTRICT_APPROVER,
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.SCHOOL_APPROVER,
];

export const distributionHistoryAllowedRoles = [
  RolesEnum.DISTRICT_ADMIN,
  RolesEnum.DISTRICT_UPLOADER,
  RolesEnum.DISTRICT_APPROVER,
  RolesEnum.SCHOOL_ADMIN,
  RolesEnum.SCHOOL_UPLOADER,
  RolesEnum.SCHOOL_APPROVER,
];

async function getCampaignRoles() {
  const res = await getFeatureFlags()
  return res.org_uploader === 1
    ? campaignAllowedRolesAsOrg
    : campaignAllowedRolesDefault
}

async function getReportingAllowedRoles() {
  const res = await getFeatureFlags()
  return res.org_uploader === 1
    ? reportingAllowedRolesAsOrg
    : reportingAllowedRolesDefault
}

// @ts-ignore
export type Roles = $Keys<typeof RolesEnum>
export default RolesEnum
