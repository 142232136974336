import React from 'react'
import MaterialSvgIcon from '@material-ui/core/SvgIcon'

const SvgIcon = props => {
  const { path } = props
  return (
    <MaterialSvgIcon {...props}>
      <path d={path} />
    </MaterialSvgIcon>
  )
}

export default SvgIcon
