import React from 'react'

const ApprovalsIcon = props => (
  <svg width="12px" height="14px" viewBox="0 0 12 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>icon - approvals</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Nav-Bar-/-Collapsed" transform="translate(-23.000000, -123.000000)">
            <g id="Group" transform="translate(8.000000, 24.000000)">
                <g id="icon---approvals" transform="translate(13.000000, 98.000000)">
                    <polygon id="Shape" points="0 0 16 0 16 16 0 16"></polygon>
                    <path d="M9.33333333,1.33333333 L4,1.33333333 C3.26666667,1.33333333 2.67333333,1.93333333 2.67333333,2.66666667 L2.66666667,13.3333333 C2.66666667,14.0666667 3.26,14.6666667 3.99333333,14.6666667 L12,14.6666667 C12.7333333,14.6666667 13.3333333,14.0666667 13.3333333,13.3333333 L13.3333333,5.33333333 L9.33333333,1.33333333 Z M8.66666667,6 L8.66666667,2.33333333 L12.3333333,6 L8.66666667,6 Z M10.3966116,8 L11.3966116,9 L7.32439886,13 L4.7,10.4039078 L5.7,9.40390781 L7.32439886,11 L10.3966116,8 Z" id="Shape" fill="#2D344B" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default ApprovalsIcon
