import * as React from 'react'
import { isProd } from '../config/constants'

type SubappNavItemConfig = {
  label: string,
  CustomItem?: any, // TODO: React.Element<typeof SidebarItem>,
  fragmentOnUser?: any, // TODO fragment type
  sideNavEnabled: boolean,
}
type SubappAppConfig = {
  Component: React.ComponentType<any>,
  fragmentOnUser?: any, // TODO fragment type
}
export type SubappConfig = {
  path: string,
  isLive: boolean,
  navItemConfig: SubappNavItemConfig,
  appConfig: SubappAppConfig,
}
type SectionConfig = {
  sectionLabel: string,
  apps: SubappConfig[],
}

export type SideNavConfig = {
  label: string;
  path: string;
  externalLink: boolean;
  showInSideNav: boolean;
  isLive: boolean;
}

type SideNavSections = {
  sideNavSections: SideNavConfig[]
}

// Types transforming app setup to app (not used when adding an app)
// App specific setup code: get things ready for the sidebar, etc.
type SidebarItem = SubappNavItemConfig & {
  path: string,
}
type SidebarSection = {
  sectionLabel: string,
  items: SidebarItem[],
}
export type SidebarConfig = SidebarSection[]

type SubappApp = SubappAppConfig & {
  path: string,
} | null

type AppConfig = {
  apps: SubappApp[],
  sidebarSections: SidebarSection[],
}

const getProdConfig = (sectionConfigs: SectionConfig[]) => {
  const getProdAppsForSection = section =>
    section.apps.filter(({ isLive }) => isLive);
  const sectionsWithProdItems = sectionConfigs.map(section => ({
    ...section,
    apps: getProdAppsForSection(section),
  }));
  const prodSections = sectionsWithProdItems.filter(
    section => section.apps.length > 0
  );
  return prodSections
}

const getSidebarItemsForSection = (
  section: SectionConfig
): SidebarItem[] =>
  section.apps.map(item => ({ ...item.navItemConfig, path: item.path }))

const getSubappAppsForSection = (section: SectionConfig): SubappApp[] =>
  section.apps.map(item => ({ ...item.appConfig, path: item.path }))

const getProdSideNavConfig = (sections: SideNavConfig[]) => {
  return sections.filter(({ isLive }) => isLive)
}

export const generateSideNavConfig = (sections: SideNavConfig[]): SideNavSections => {
  const sideNavConfig = isProd ? getProdSideNavConfig(sections) : sections
  return {
    sideNavSections: sideNavConfig
  }
}

const generateAppConfig = (sectionConfigs: SectionConfig[]): AppConfig => {
  const config = isProd ? getProdConfig(sectionConfigs) : sectionConfigs

  const appConfig = config.reduce(
    // @ts-ignore
    (accum, section) => {
      const sidebarItems = getSidebarItemsForSection(section)
      const subapps = getSubappAppsForSection(section)
      const newSidebarSection = {
        sectionLabel: section.sectionLabel,
        items: sidebarItems,
      }

      return {
        sidebarSections: [...accum.sidebarSections, newSidebarSection],
        apps: [...accum.apps, ...subapps],
      }
    },
    { sidebarSections: [], apps: [] } as AppConfig
  )

  return appConfig
}

export default generateAppConfig
