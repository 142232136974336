import React, { useContext } from 'react'
import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { ButtonFlatSmall } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import useGlobalNotifications from '@peachjar/ui/dist/lib/hooks/useGlobalNotifications'
import DealsContext from '../../middleware/context/DealsContext'
import CreditsModal from '../PurchaseModal/PurchaseModal'

type Props = {
  variant?: string;
}

const CreditsMenu: React.FunctionComponent<Props> = ({
  variant = 'brandbar'
}) => {
  const [ showCreditsModal, setShowCreditsModal ] = React.useState<boolean>(false)
  // @ts-ignore
  const { creditBalance } = useContext(DealsContext)

  const { notifyError } = useGlobalNotifications()

  const closePurchaseModal = () => setShowCreditsModal(false)

  const handleAddCredits = () => setShowCreditsModal(true)

  return (
        <div
          className={`${cn.creditsMenu} ${variant}`}
          data-testid="menu-credits-brandbar"
        >
          <span className="creditAmount">{creditBalance} credits</span>
          <ButtonFlatSmall
            className="addCreditsCTA"
            onClick={handleAddCredits}
          >
            Add...
          </ButtonFlatSmall>
          { showCreditsModal && <CreditsModal closeModal={closePurchaseModal} /> }
        </div>
  )
}

const cn = {
  creditsMenu: css`
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    margin-right: 8px;

    &.brandbar {
      .creditAmount {
        color: ${colors.prussian};
        font-size: 13px;
        font-weight: 600;
        padding-right: 8px;
      }
      .addCreditsCTA {
        font-size: 13px;
      }
    }

    &.processbar {
      .creditAmount {
        color: ${colors.white};
        font-size: 13px;
        font-weight: 600;
        padding-right: 30px;
      }
      .addCreditsCTA {
        display: none !important;
      }
    }
  `,
}

export default CreditsMenu
