import { components } from '@peachjar/components'
import store from '../middleware/redux/store/store'

const {
  notifySuccess: alertSuccess,
  notifyInfo: alertInfo,
  notifyWarn: alertWarning,
  notifyError: alertError,
} = components.Notifications

export function notifySuccess(message: string): void {
  store.dispatch(alertSuccess(message))
}

export function notifyInfo(message: string): void {
  store.dispatch(alertInfo(message))
}

export function notifyWarning(message: string): void {
  store.dispatch(alertWarning(message))
}

export function notifyError(message: string): void {
  store.dispatch(alertError(message))
}
