import config from '../config/config'

const { REACT_APP_PEACHJAR_ACCOUNT_LINK } = config

export function isGqlAuthError(errors: any[]): boolean {
  return errors.some(
    (err) =>
      err.hasOwnProperty('message') &&
      err.message.includes('is not authenticated')
  )
}

export default function handleGqlAuthError(error: string) {
  window.__PJ__.logger.capture(error)
  window.location.href = `${REACT_APP_PEACHJAR_ACCOUNT_LINK}?destination=${window.location.href}`
}
