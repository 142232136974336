import React from 'react'

const DistributionHistoryIcon = props => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M9.14286 1C5.35619 1 2.28571 4.13444 2.28571 8H0L2.96381 11.0256L3.01714 11.1344L6.09524 8H3.80952C3.80952 4.99 6.19429 2.55556 9.14286 2.55556C12.0914 2.55556 14.4762 4.99 14.4762 8C14.4762 11.01 12.0914 13.4444 9.14286 13.4444C7.67238 13.4444 6.33905 12.83 5.37905 11.8422L4.29714 12.9467C5.53905 14.2144 7.24571 15 9.14286 15C12.9295 15 16 11.8656 16 8C16 4.13444 12.9295 1 9.14286 1ZM8.38095 4.88889V8.77778L11.619 10.7378L12.2057 9.74222L9.52381 8.11667V4.88889H8.38095Z" fill="#2D344B"/>
</svg>
)

export default DistributionHistoryIcon

