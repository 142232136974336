
import React, { useState, useEffect } from 'react'
import { css, cx } from 'emotion'
import gql from 'graphql-tag'
import idx from 'idx'
import { elements } from '@peachjar/components'
import apolloBffClient from '../../middleware/apollo/apolloBffClient'

// const { Badge } = components;
const { Note } = elements.typography

type BadgeProps = {
  children: string | number,
  color?: string,
  className?: string,
}

const GET_APPROVAL_CENTER_REGIONS = gql`
  query getApprovalCenterRegions {
    approvalCenterRegions {
      id
      pendingApprovalCount
    }
  }
`;

export const Badge = ({ color, children, className }: BadgeProps) => (
  <Note className={cx(classNames.badge(color), className)}>{children}</Note>
)

const classNames = {
  badge: color => css`
    display: inline-block;
    padding: 0.125rem 0.25rem;
    background-color: ${color};
    border-radius: 10px;
    margin-left: 0.25rem;
    font-weight: normal;
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
    min-width: 1.5rem;
    text-align: center;
  `,
}

Badge.defaultProps = {
  color: '#c5e8c9', // Some variation on mint
  className: '',
}

const getBadgeCount = async () => {
  try {
    const result = await apolloBffClient.query({
      query: GET_APPROVAL_CENTER_REGIONS,
      fetchPolicy: 'no-cache',
    })

    // const pendingApprovalCount =
    //   idx(result, _ => _.data.approvalCenterRegions.pendingApprovalCount) || 0

    const regions = idx(result, _ => _.data.approvalCenterRegions)
    const pendingApprovalsCount = regions.reduce(
      (total, { pendingApprovalCount }) => total + pendingApprovalCount,
      0
    );

    return pendingApprovalsCount
  } catch (e) {
    console.log(e)
  }
}

const PendingApprovalsBadge = () => {
  const [badgeCount, setBadgeCount] = useState(0)

  useEffect(() => {
    const broker = window.__PJ__.eventBroker
    const setApprovalsBadgeCount = async () => {
      try {
        const numberOfApprovals = await getBadgeCount()
        setBadgeCount(numberOfApprovals)
      } catch (e) {
        console.log('Unable to fetch pending approvals count ', e)
      }

    }

    const pendingApprovalsBadgeSubscription = broker.subscribe({
      channel: "/",
      topic: "sidenav.updatePendingBadge",
      callback: () => {
        setTimeout(async () => {
          const count = await getBadgeCount()
          setBadgeCount(count)
        }, 2000)
      }
    })

    setApprovalsBadgeCount()

    return () => {
      pendingApprovalsBadgeSubscription.unsubscribe()
    }
  }, [])

  // @ts-ignore
  return <Badge>{badgeCount}</Badge>
}

export default PendingApprovalsBadge
