import * as React from 'react'
import { css, cx } from 'emotion'
import { connect } from 'react-redux'
import { matchPath } from 'react-router-dom'
import { components, colors } from '@peachjar/components'
import { APP_ROUTES } from '../../config/constants'
import logout from '../../utils/logout'
import { Flags } from '../../utils/flags'
import { LinkedAccount } from '../../types/profile'
import SwitchAccounts from '../SwitchAccounts/SwitchAccounts'
import getLink from '../../utils/getLinks'

const { Profile } = components

type Props = {
  userId: string;
  entityName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  accountType: string;
  avatarURI: string;
  flags: Flags;
  uploaderLevel: string;
  linkedAccounts: LinkedAccount[];
  getUserType: (scopes: string[]) => string;
}

const ProfileDropdown = ({
  userId,
  entityName,
  firstName,
  lastName,
  emailAddress,
  accountType,
  avatarURI,
  flags,
  uploaderLevel,
  linkedAccounts,
  getUserType
}: Props) => {
  const dropDownConfig = {
    activeLinks: [
      APP_ROUTES.account,
      APP_ROUTES.account_info,
      APP_ROUTES.transactions,
      APP_ROUTES.help,
    ],
    profileLinks: [
      {
        text: 'Transaction History',
        link: APP_ROUTES.transactions,
        linkType: 'external',
        requiresFeatureFlag: true,
        requiresRoleCheck: true,
        allowedRoles: ['org'],
        featureFlag: 'org_uploader',
      },
      {
        text: 'Account Information',
        link: APP_ROUTES.account_info,
        linkType: 'external',
        requiresFeatureFlag: false,
        requiresRoleCheck: false,
        allowedRoles: [],
        featureFlag: '',
      },
      {
        text: 'Help',
        link: APP_ROUTES.help,
        linkType: 'external',
        requiresFeatureFlag: false,
        requiresRoleCheck: false,
        allowedRoles: [],
        featureFlag: '',
      },
    ],
  }

  const isPortalNavEnabled = flags.portal_nav === 1
  const { location: { pathname } } = window
  const { activeLinks, profileLinks } = dropDownConfig

  const isDropDownActive = activeLinks.includes(pathname)

  const getUserName = () => `${firstName} ${lastName}`.trim()

  return (
    <Profile
      name={firstName}
      emailAddress={emailAddress}
      profilePictureURI={avatarURI}
      entityName={entityName}
      fullName={getUserName()}
      data-testid="menu-profile-dropdown"
      className={cx(
        classNames.dropdownTrigger,
        {
          [classNames.dropDownActive]: isPortalNavEnabled && isDropDownActive
        }
      )}
    >
      <SwitchAccounts
        userId={userId}
        fullName={getUserName()}
        linkedAccounts={linkedAccounts}
        getUserType={getUserType}
      />
      {isPortalNavEnabled && (
        <ul className={classNames.profileMenu}>
          {profileLinks.map(
            (
              {
                text,
                link,
                linkType,
                requiresFeatureFlag,
                featureFlag,
                allowedRoles,
                requiresRoleCheck,
              },
              index
            ) => {
              if (
                !requiresFeatureFlag ||
                (requiresFeatureFlag && flags[featureFlag])
              ) {
                // @ts-ignore
                if ( requiresRoleCheck && !allowedRoles.includes(uploaderLevel)) {
                  return <></>
                }
                const isActive = !!matchPath(pathname, { path: link })

                return (
                  <li key={index}>
                    {getLink(
                      text,
                      link,
                      linkType,
                      cx(
                        classNames.profileLink,
                        { [classNames.activeLink]: isActive }
                      )
                    )}
                  </li>
                )
              }
              return <></>
            }
          )}
          {/** handle logout separately until DEV-3949 & DEV-3856 are addressed  */}
          <li>
            <button
              type="button"
              className={classNames.profileLink}
              onClick={() => logout()}
              data-testid="click-profiledropdown-logout"
            >
              Log Out
            </button>
          </li>
        </ul>
      )}
    </Profile>
  )
}

const classNames = {
  dropdownTrigger: css`
    border-left: 1px solid #e6e6e6;
  `,
  dropDownActive: css`
    border-bottom: 4px solid ${colors.leaf};
  `,
  profileMenu: css`
    margin: 0;
    list-style-type: none;
    padding: 8px;
  `,
  profileLink: css`
    display: block;
    color: ${colors.prussian};
    padding: 17px 15px;
    font-size: 13px;
    border-radius: 3px;
    width: 100%;
    border: none;
    background: transparent;
    text-align: left;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      background-color: #ededed;
      color: ${colors.prussian};
    }
  `,
  activeLink: css`
    border-left: 4px solid ${colors.leaf};
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.03);
  `,
}

export default connect(
  // @ts-ignore
  state => ({ flags: state.flags }),
  null
)(ProfileDropdown)
