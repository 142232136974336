import * as React from 'react'
import { css, cx } from 'emotion'
import { colors } from '@peachjar/components'

type Props = {
  path: string;
  className?: string;
  children: React.ReactNode;
}

const VerticalNavAnchor = ({ path, children, className = '', ...rest }: Props) => (
  <a href={path} className={cx(classNames.aReset, classNames.link, className)} {...rest}>
    {children}
  </a>
)

const classNames = {
  aReset: css`
    text-decoration: none;
    color: ${colors.prussian};
    &:hover {
      color: ${colors.prussian};
      text-decoration: none;
    }
  `,
  link: css`
    display: block;
    width: 100%;
    padding: 1rem 1rem 1rem 0.5rem;

    @media screen and (min-width: 1920px) {
      padding: 1rem;
    }
  `,
}

export default VerticalNavAnchor
