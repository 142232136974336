import React, { useState, FormEvent } from 'react'
import { css } from 'emotion'
import { components, colors } from '@peachjar/components'
import { PurchaseModalConfig as config } from './PurchaseModal.config'
import envConfig from '../../config/config'
import publishEvent from '../../utils/publishEvent'
import { EventTopics } from '../../config/constants'

const { REACT_APP_DEALS_URL } = envConfig

type Props = {
  variant?: string,
  width?: string,
  credits?: string | null,
  closeModal: () => void,
  applyDeal: () => void,
}

type ValidateInputProps = {
  e: FormEvent<HTMLInputElement>,
  validator: () => void,
  setState: () => void,
}

const {
  Modal,
  TextInput,
  Buttons: {
    ButtonFlatLarge
  }
} = components

const {
  Header,
  Body,
  Footer
} = Modal

const PurchaseModal = ({
  variant = 'addCredits',
  width = 'sm',
  credits = null,
  applyDeal = () => {},
  closeModal
}) => {
  const [purchase, setPurchase] = useState({
    credits,
    error: false,
  })
  // const { credits: storeCredits, setCredits } = useContext(DealsContext)
  const { credits: purchaseCredits } = purchase
  const _variant = config[variant]

  const submitSelection = (): void => {
    if (!!purchaseCredits) {
      if (`${window.location.origin}${window.location.pathname}` === REACT_APP_DEALS_URL) {
        publishEvent(EventTopics.AddCredits, purchaseCredits)
        closeModal()
      } else {
        window.location.href = `${REACT_APP_DEALS_URL}?credits=${purchaseCredits}`
      }
    }
  }

  const validateInput = ({
    e,
    validator,
    setState,
  }: ValidateInputProps): void => {
    const {
      // @ts-ignore
      target: { value },
    } = e
    // @ts-ignore
    const isNumber: boolean = validator(value)

    // @ts-ignore
    setState({ credits: value, error: !isNumber })
  }

  return (
    <Modal width={width} close={closeModal} data-testid="purchase-creds-modal">
      <Header>{_variant.header}</Header>
      <Body>
        <div className={cn.modalBody}>
          <p className={cn.modalBodyMain}>{_variant.body}</p>
          <p className={cn.modalDisclaimer}>{_variant.disclaimer}</p>
        </div>
        <div className={cn.modalBodyForm}>
          <TextInput
            autoFocus
            fullWidth
            type="text"
            placeholder="0"
            label={_variant.label}
            error={purchase.error}
            onChange={e =>
              validateInput({
                e,
                // @ts-ignore
                validator: config.validate,
                // @ts-ignore
                setState: setPurchase
              })
            }
            value={purchaseCredits ? purchaseCredits : ""}
            data-testid={`field-${variant}-input`}
          />
          <div
            role="alert"
            className={`${cn.errorAlert} ${purchase.error ? "show" : ""}`}
          >
            {_variant.error}
          </div>
        </div>
      </Body>
      <Footer>
        <ButtonFlatLarge
          onClick={closeModal}
          data-testid={`click-cancel-modal-${variant}`}
          id="modal-btn-left"
          className={cn.modalCTA}
          style={{ position: 'static', backgroundColor: 'transparent' }}
        >
          Cancel
        </ButtonFlatLarge>
        <ButtonFlatLarge
          disabled={purchase.error || !purchaseCredits}
          data-testid={`click-save-modal-${variant}`}
          onClick={submitSelection}
          id="modal-btn-right"
          className={cn.modalCTA}
          style={{ position: 'static', backgroundColor: 'transparent' }}
        >
          {_variant.submitCTA}
        </ButtonFlatLarge>
      </Footer>
    </Modal>
  )
}

const cn = {
  modalCTA: css`
    &#modal-btn-right,
    &#modal-btn-left {
      position: static !important;
      width: auto !important;
      transform: none !important;
      background-color: transparent !important;
    }
      & > span {
        color: ${colors.jungle} !important;
        background-color: transparent !important;
        border: none !important;
      }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
      box-shadow: none !important;

      & > span {
        color: ${colors.jungle} !important;
      }
    }
  `,
  modalBody: css`
    padding: 10px 0 0 0;
  `,
  modalBodyMain: css`
    font-size: 16px;
    margin-bottom: 0;
    color: ${colors.prussian};
  `,
  modalBodyForm: css`
    width: 384px;
    margin: 0 auto;
  `,
  modalDisclaimer: css`
    padding: 0;
    font-weight: 600;
    margin-top: 2px;
  `,
  errorAlert: css`
    color: ${colors.dragon};
    font-size: 14px;
    display: none;

    &.show {
      display: block;
    }
  `,
}

export default PurchaseModal
