// @ts-nocheck
import * as React from 'react'
import gql from 'graphql-tag'
import idx from 'idx'
import UserContext from '../middleware/context/UserContext'
import RolesEnum from '../config/rolesEnum'
import config from '../config/config'
import { NOTIFICATIONS } from '../config/constants'

// specifically for the brand bar
export const getAccountType = sod => {
  switch (sod) {
    case 'district':
      return 'District Account'
    case 'org':
      return 'Organization Account'
    case 'communityFree':
      return 'Community Free Account'
    default:
      return 'School Account'
  }
}

/**
 * Tech Debt (DEV-3436): The permission system is not well thought out.
 * We need to create a more robust system not specifically tailored to
 * the beta approval center.
 */
export const getRolesFromPermissions = (
  approverLevel?: Object,
  adminLevel?: Object,
  uploaderLevel?: Object
): any => {
  const roles = []

  if (approverLevel === 'school') {
    roles.push(RolesEnum.SCHOOL_APPROVER)
  }
  if (approverLevel === 'district') {
    roles.push(RolesEnum.DISTRICT_APPROVER)
  }
  if (adminLevel === 'school') {
    roles.push(RolesEnum.SCHOOL_ADMIN)
  }
  if (adminLevel === 'district') {
    roles.push(RolesEnum.DISTRICT_ADMIN)
  }
  if (uploaderLevel === 'school') {
    roles.push(RolesEnum.SCHOOL_UPLOADER)
  }
  if (uploaderLevel === 'district') {
    roles.push(RolesEnum.DISTRICT_UPLOADER)
  }
  if (uploaderLevel === 'org') {
    roles.push(RolesEnum.ORG)
  }
  if (uploaderLevel === 'communityFree') {
    roles.push(RolesEnum.COMMUNITY_FREE)
  }

  return roles
}

// TODO: write some basic tests right now
export const shouldUserSeeChildren = (
  allowedRolesForApp: any,
  userRoles: any
): boolean => {
  // If no roles were specified, assume all roles can view children
  if (!Array.isArray(allowedRolesForApp)) {
    return true
  }

  return allowedRolesForApp.reduce((userShouldSeeApp, nextRoleSet) => {
    if (userShouldSeeApp) {
      return true
    }
    if (typeof nextRoleSet === 'string') {
      return userRoles.includes(nextRoleSet)
    }
    if (Array.isArray(nextRoleSet)) {
      // check that user has all the roles in the set
      return nextRoleSet.every(role => userRoles.includes(role))
    }
    return false
  }, false)
}

// TODO: this could be a query
type Props = {
  children: React.ReactNode,
  roles?: any,
}

const getUserRoleData = queryData => {
  return {
    approverLevel:
      idx(queryData, _ => _.profile.permission.approverLevel) || null,
    adminLevel: idx(queryData, _ => _.profile.permission.adminLevel) || null,
    uploaderLevel:
      idx(queryData, _ => _.profile.permission.uploaderLevel) || null,
  }
}

const RoleCheck = ({ children, roles = null }: Props) => {
  const { userData } = React.useContext(UserContext)
  const { approverLevel, adminLevel, uploaderLevel } = getUserRoleData(userData)
  const userRoles = getRolesFromPermissions(
    approverLevel,
    adminLevel,
    uploaderLevel
  )

  return shouldUserSeeChildren(roles, userRoles) ? children : null
}

// Enhancing RoleChecWithRedirect with the ability to share roles via context
export const RolesContext = React.createContext({
  uploaderLevel: '',
})
export const RolesProvider = RolesContext.Provider
export const useRolesContext = () => React.useContext(RolesContext)

export default RoleCheck
