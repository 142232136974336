import gql from 'graphql-tag'

export const GET_ACCOUNT_FEATURE_FLAGS = gql`
  query getAccountFeatureFlags {
    profile {
      featureFlags {
        portal_nav
        portal_nav_banner
        upload_flyer
        approval_center
        approval_center_banner
        org_uploader
        managed_services
      }
    }
  }
`
