import client from '../middleware/apollo/apolloBffClient'
import { GET_ACCOUNT_FEATURE_FLAGS } from '../queries/accounts/account.graphql'

export type Flags = {
  portal_nav: number;
  portal_nav_banner: number;
  upload_flyer: number;
  approval_center: number;
  approval_center_banner: number;
  org_uploader: number;
  managed_services: number;
}

export default async function getFeatureFlags(): Promise<Flags> {
  try {
    const { data, errors } = await client.query({
      query: GET_ACCOUNT_FEATURE_FLAGS,
      fetchPolicy: 'cache-first',
    })

    if (errors && errors.length > 0) {
      throw errors[0]
    }

    const {
      profile: { featureFlags },
    } = data

    return featureFlags
  } catch (e) {
    console.error('Unable to query featureFlags. ', e)
    return {
      portal_nav: 0,
      portal_nav_banner: 0,
      upload_flyer: 0,
      approval_center: 0,
      approval_center_banner: 0,
      org_uploader: 0,
      managed_services: 0,
    }
  }
}
