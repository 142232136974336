
import * as React from 'react'
import { css } from 'emotion'
import { elements } from '@peachjar/components'
import VerticalNavItem from '../VerticalNav/VerticalNavItem'
import ApprovalsIcon from '../ApprovalsIcon/ApprovalsIcon'
import DashboardIcon from '../DashboardIcon/DashboardIcon'
import DistributionIcon from '../DistributionIcon/DistributionIcon'
import ReportingIcon from '../ReportingIcon/ReportingIcon'
import SendFlyerIcon from '../SendFlyerIcon/SendFlyerIcon'
import PendingApprovalsIcon from '../PendingApprovalsIcon/PendingApprovalsIcon'
import ApprovalHistoryIcon from '../ApprovalHistoryIcon/ApprovalHistoryIcon'
import DealsIcon from '../Sidebar/components/DealsIcon'
import DistributionScheduleIcon from '../DistributionScheduleIcon'
import DistributionHistoryIcon from '../DistributionHistoryIcon'

const { Note } = elements.typography

type Props = {
  path: string
  label: string
  externalLink: boolean
  exact: boolean
  badge?: React.ReactNode
}

const sideBarIcons = {
  dashboard: DashboardIcon,
  deals: DealsIcon,
  approvals: ApprovalsIcon,
  submissions: DistributionIcon,
  reporting: ReportingIcon,
  'post&notify': SendFlyerIcon,
  pendingapprovals: PendingApprovalsIcon,
  approvalhistory: ApprovalHistoryIcon,
  distributionschedule: DistributionScheduleIcon,
  distributionhistory: DistributionHistoryIcon
}

const formatLabel = label => label.replace(/\s/g, '').toLowerCase()

const dividerArray = [
  'Dashboard',
  'Post & Notify',
  'Approval History',
  'Distribution History',
  'Reporting',
  'Deals'
]

const SidebarDrawerNavItem = ({
  path,
  label,
  externalLink,
  exact = false,
  badge = null
}: Props) => {
  const formattedLabel = formatLabel(label)
  const SideNavIcon = sideBarIcons[formattedLabel]

  return (
    <VerticalNavItem
      path={path}
      label={label}
      externalLink={externalLink}
      exact={exact}
      className={`${dividerArray.includes(label) ? classNames.divider : ""}`}
    >
      <Note className={classNames.spaceBetween}>
        <SideNavIcon
          className={classNames.icon}
          height="16"
          width="16"
          alt={label}
          data-testid={`icon-nav-item-${label}`}
        />
        <span
          className={classNames.itemLabel}
          data-testid={`text-nav-item-label-${label}`}
        >
          {label}
        </span>
        <span
          className={classNames.itemBadge}
          data-testid={`text-nav-item-badge`}
        >
          {badge}
        </span>
      </Note>
    </VerticalNavItem>
  )
}

const classNames = {
  spaceBetween: css`
    display: block;
    position: relative;
    padding: 10px;
  `,
  icon: css`
    position: absolute;
    left: 4px;
    top: 2px;
  `,
  itemLabel: css`
    position: absolute;
    left: 40px;
    top: 2px;
  `,
  itemBadge: css`
    position: absolute;
    left: 150px;
    top: 2px;
  `,
  divider: css`
    border-bottom: 1px solid rgba(0,0,0,0.1);
  `,
}

export default SidebarDrawerNavItem
