export const PurchaseModalConfig = {
  addCredits: {
    header: 'Add Credits',
    body: 'Enter the amount of credits you would like to add.',
    disclaimer: 'Note: Bulk credit discounts auto-apply starting at 100 credits.',
    label: 'Credits',
    submitCTA: 'Add',
    error: 'Enter a whole number greater than 0.'
  },
  editCredits: {
    header: 'Edit Credits',
    body: 'Edit the total amount of credits you would like to add.',
    disclaimer: 'Note: Bulk credit discounts auto-apply starting at 100 credits.',
    label: 'Credits',
    submitCTA: 'Save',
    error: 'Enter a whole number greater than 0.'
  },
  validate(value: string): boolean {
    const isNumberRegEx = /^[1-9]\d*$/
    let isNumber = false

    if (isNumberRegEx.test(value)) {
      isNumber = true
    }

    return isNumber
  }
}
