type EventPayload = {
  [key: string]: any
}

export default function publishEvent(
  topic: string,
  payload: EventPayload | null
): void {
  try {
    window.__PJ__.eventBroker.publish({
      channel: '/',
      topic,
      data: payload,
    })
  } catch (e) {
    if (window.__PJ__.logger) {
      window.__PJ__.logger.capture(e)
    }
    console.log(e)
  }
}
