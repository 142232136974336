import { registerMicroAppLifeCycles } from '@peachjar/kernel-sanders'
import App from './components/AppWrapper/AppWrapper'
import constants from './config/constants'

const appLifeCycles = registerMicroAppLifeCycles({
  rootComponent: App,
  mountTargetId: constants.APP_ROOT_ID
})

export function mount(props) {
  function waitForElement() {
    const poll = (resolve) => {
      if (document.getElementById(constants.APP_ROOT_ID)) {
        resolve()
      } else {
        setTimeout(() => poll(resolve), 400)
      }
    }

    return new Promise(poll)
  }

  return waitForElement().then(() => {
    return appLifeCycles.mount(props)
  })
}

export function bootstrap(props) {
  return appLifeCycles.bootstrap(props)
}

export function unmount(props) {
  return appLifeCycles.unmount(props)
}
