// @ts-nocheck
import React, { useEffect, useState} from 'react';
import { sortBy } from 'lodash'
import gql from 'graphql-tag'
import idx from 'idx'
import { connect } from 'react-redux'
import { css, cx } from 'emotion'
import { components } from '@peachjar/components'
import config from '../../config/config'
import CreditsMenu from '../CreditsMenu/CreditsMenu'
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown'
import Settingsdropdown from '../SettingsDropdown/SettingsDropdown'
import { getRolesFromPermissions } from '../../utils/RoleCheck'
import RolesEnum from '../../config/rolesEnum'
import { Flags } from '../../utils/flags'
import useAccountContext from '../../hooks/useAccountContext';
import apolloClient from '../../middleware/apollo/apolloBffClient'
import { LinkedAccount } from '../../types/profile'
import FlyerBoardDropDown from '../FlyerBoardDropdown/FlyerBoardDropdown';


const { Brandbar: PJBrandbar } = components
const { DISTRICT_ADMIN, SCHOOL_ADMIN, ORG } = RolesEnum
const { DASHBOARD_URL } = config

type Props = {
  sodUser?: object,
  processMode: boolean,
  flags: Flags
}

type ForEntity = {
  forEntity: {
    entityType: string;
    entityId: string;
  }
}

type Entity = {
  id: number;
  name: string;
  type: string;
}


const GET_SCHOOLS_BY_SCHOOL_ID = gql`
  query getSchoolsBySchoolId($schoolIds: [Int!]!) {
    getSchoolsBySchoolId(schoolIds: $schoolIds){
      items {
        schoolId
        name
      }
    }
  }
`;

export const GET_DISTRICT_NAME_BY_ID = gql`
  query getDistrict($input: V2_DistrictIdsInput) {
    getDistrict(input: $input){
      name
    }
  }
`

export const GET_ROLES_FOR_USER = gql`
  query getRolesForUser($input: V2_GetRolesForUserInput) {
    getRolesForUser(input: $input){
      forEntity {
        entityType
        entityId
      }
    }
  }
`;

interface Role {
  forEntity: ForEntity
  roles: string[]
}

export function getTopRoleEntity(roles: Role[]): ForEntity | undefined {
  const isAdmin = r => (idx(r, _=> _.roles) || []).includes('admin')
  const isDistrict = r => idx(r, _ => _.forEntity.entityType) === 'district'

  const topResult = sortBy(roles.filter(isAdmin), r => !isDistrict(r))[0]
  const result = idx(topResult, _ => _.forEntity)

  return result
}

const Brandbar = ({ sodUser, processMode, flags }: Props) => {
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[]>([])
  const { data: accountData } = useAccountContext();

  const _profileAccount = accountData?.profile

  const getRolesForUser = async (userIdParam: number): Promise<ForEntity[]> => {
    const { data: { getRolesForUser } } = await apolloClient.query({
      query: GET_ROLES_FOR_USER,
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
      variables: {
        input: {
          userId: userIdParam.toString()
        }
      }
    })

    if (getRolesForUser && getRolesForUser.length > 0) {
      return getRolesForUser;
    }
    return []
  }

  const getEntity = async (entities: ForEntity[]): Promise<Entity | null> => {
    if (entities.length > 0) {
      const districtEntities = entities.filter(({ forEntity: { entityType } }) => entityType === 'district')
      let userEntities: ForEntity[] = districtEntities
      if (districtEntities.length === 0) {
        userEntities = entities.filter(({ forEntity: { entityType } }) => entityType === 'school')
        userEntities = userEntities.sort((a,b) => parseInt(a.forEntity.entityId) - parseInt(b.forEntity.entityId))
      }

      const { forEntity } = userEntities[0]
      const entityId = Number(forEntity.entityId)
      let name: string | null = ''
      if (forEntity.entityType === 'district') {
        name = await getDistrictNameById(entityId)
      } else {
        name = await getSchoolNameById(entityId)
      }

      if (name) {
        const moreEntities = forEntity.entityType === 'school' ? entities.length > 1 ? `+more` : '' : ''

        return {
          id: entityId,
          name: `${name} ${moreEntities}`,
          type: forEntity.entityType
        }
      }
    }
    return null
  }

  useEffect(() => {
    const getLinkedAccounts = async (accounts: LinkedAccount[]) => {
      const linkedAccountList: LinkedAccount[] = [];
      for(let i = 0; i < accounts.length; i++) {
        let account = accounts[i];

        if(account.scopes.includes("staff") || account.scopes.includes("volunteer")) {
          const entities = await getRolesForUser(account.userId)
          const entity = await getEntity(entities)
          if (entity) {
            linkedAccountList.push({
              ...account,
              hierarchy: {
                id: entity.id.toString(),
                name: `${entity.name}`,
                type: entity.type
              }
            })
            continue
          }
        }
        linkedAccountList.push(account)
      }
      setLinkedAccounts(linkedAccountList)
    }

    const linkedAccounts = _profileAccount?.linkedAccounts ?? [];
    if (linkedAccounts.length > 0) {
      getLinkedAccounts(linkedAccounts)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_profileAccount])

  const getSchoolNameById = async (entityId: number): Promise<string | null> => {
    const { data: { getSchoolsBySchoolId } } = await apolloClient.query({
      query: GET_SCHOOLS_BY_SCHOOL_ID,
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
      variables: {
        schoolIds: entityId
      }
    })

    if (getSchoolsBySchoolId && getSchoolsBySchoolId.items.length > 0) {
      const name = getSchoolsBySchoolId.items[0].name
      return name;
    }

    return null
  }

  const getDistrictNameById = async (districtId: number): Promise<string | null> => {
    const { data: { getDistrict: districtName } } = await apolloClient.query({
      query: GET_DISTRICT_NAME_BY_ID,
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
      variables: {
        input: {
          districtId
        }
      }
    })

    if (districtName) {
     return districtName.name
    }

    return null
  }

  const getUserType = (scopes: string[]): string => {
    if (scopes.includes('parent')) {
      return 'Parent'
    }

    if (scopes.includes('volunteer')) {
      return 'Volunteer'
    }

    if (scopes.includes('staff')) {
      return 'Staff'
    }

    if (scopes.includes('org_member')) {
      return 'Organization'
    }

    return ''
  }

  /**
   * TODO: Move account type calculation to the backend.
   * Note - The method below will break when working with program providers.
   */
  const { org_uploader, portal_nav } = flags
  const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null
  const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null
  const uploaderLevel = idx(sodUser, _ => _.permission.uploaderLevel) || null
  const hierarchy = idx(sodUser, _ => _.hierarchy)
  const scopes = idx(sodUser, _ => _.scopes) || [];
  const userId = idx(sodUser, _ => _.id) || null
  const adminRoles = getRolesFromPermissions(approverLevel, adminLevel, uploaderLevel)
  const shouldRenderCreditsMenu = uploaderLevel === 'org' || uploaderLevel === 'communityFree'
  const shouldRenderSettingsMenu =
  adminRoles.includes(DISTRICT_ADMIN) ||
  adminRoles.includes(SCHOOL_ADMIN) ||
  adminRoles.includes(ORG);
  const userType = getUserType(scopes)
  const isStaffOrVolunteer = scopes.includes('volunteer') || scopes.includes('staff')
  const firstName = idx(sodUser, _ => _.firstName) || userType
  const lastName = idx(sodUser, _ => _.lastName) || ''
  const emailAddress = idx(sodUser, _ => _.email) || 'No email provided.'

  const orgUploaderFlagEnabled = org_uploader === 1
  const portalNavFlagEnabled = portal_nav === 1
  const defaultTopEntity = {
    entityId: hierarchy.id,
    entityType: hierarchy.type,
  }
  const roles = idx(sodUser, _ => _.roles) || []
  const schoolRolesAdmin = roles.filter((role) => 
    role.forEntity.entityType === 'school' &&
    role.roles.includes('admin') 
  )
  const hasMultipleSchoolsAdmin = schoolRolesAdmin.length > 1 

  const topRoleEntity = getTopRoleEntity(roles) || defaultTopEntity

  let entityName = '';
  const account = linkedAccounts.find(account => account.userId.toString() === userId.toString())
  if (account) {
    entityName = account.hierarchy.name
  }

  return (
    <>
      <PJBrandbar
        logoURI={DASHBOARD_URL}
        className={classNames.brandbar}
        dark={processMode}
      >

        <div
          className={cx(classNames.brandbarContent, "appBrandbar")}
          data-testid="element-brandbar"
        >

          {!processMode && (
            <>
              {orgUploaderFlagEnabled && shouldRenderCreditsMenu && (
                <CreditsMenu />
              )}
              {isStaffOrVolunteer && <FlyerBoardDropDown />}
              {portalNavFlagEnabled && shouldRenderSettingsMenu && (
                <Settingsdropdown
                  roles={adminRoles}
                  hierarchy={hierarchy}
                  topRoleEntity={topRoleEntity}
                  hasMultipleSchoolsAdmin={hasMultipleSchoolsAdmin}
                />
              )}
              <ProfileDropdown
                userId={userId}
                entityName={entityName}
                linkedAccounts={linkedAccounts}
                firstName={firstName}
                lastName={lastName}
                emailAddress={emailAddress}
                uploaderLevel={uploaderLevel}
                getUserType={getUserType}
              />
            </>
          )}
        </div>
      </PJBrandbar>
    </>
  )
}

Brandbar.fragments = {
  sodUser: gql`
    fragment Brandbar_sodUser on SodUser {
      id
      permission {
        adminLevel
        approverLevel
        uploaderLevel
      }
      firstName
      email
    }
  `,
}

const classNames = {
  brandbar: css`
    z-index: 50;
  `,
  brandbarContent: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    &.appBrandbar button[data-testid="click-profile-dropdown"] {
      position: relative !important;
      background-color: transparent !important;

      > span {
        border: none !important;
        background-color: transparent !important;
      }
    }
  `,
  innerPillStyles: css`
  display:flex;
  margin: auto;
  `,
  pillStyles: css`
  display:flex;
  flex-direction: column;
  align-content: center;
  align-self: center;
  font-family: ProximaNova-Regular;
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  `
}

export default connect(
  state => ({ flags: state.flags }),
  null
)(Brandbar)
