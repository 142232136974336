import React, { useMemo, useRef, useState, useEffect } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { css, cx } from 'emotion'
import { gql } from 'apollo-boost'
import flyerBoardSvg from '../../assets/flyerboard_icon.svg'
import useAccountContext from '../../hooks/useAccountContext'
import apolloBffClient from '../../middleware/apollo/apolloBffClient'
import config from '../../config/config'

const { colors } = require('@peachjar/components')

const GET_DISTRICTS_AND_SCHOOLS = gql`
  query getDistrictsAndSchools($districtId: Int!) {
    district: getDistrict (input: {districtId: $districtId}) {
      name
      districtId
      schools {
        schoolId
        name
        transitionalGroupType
        active
      }
    }
  }
`

async function getDistrict (districtId) {
  const { data } = await apolloBffClient.query({
    query: GET_DISTRICTS_AND_SCHOOLS,
    fetchPolicy: 'cache-first',
    variables: {
      districtId
    }
  })
  return data.district
}

const flyerBoardRoutePattern = /\/flyers\/.+/

const FlyerBoardDropDown = () => {
  const flyerBoardButton = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const [isSchoolStaff, setIsSchoolStaff] = useState(false)
  const [schoolStaff, setSchoolStaff] = useState<any[]>([])
  const [district, setDistrict] = useState<any>(null)
  const id = useMemo(() => open ? 'flyers-popper' : undefined, [open])
  const { data: accountData } = useAccountContext()

  useEffect(() => {
    if (accountData) {
      getDistrict(parseInt(accountData.profile.hierarchy.id)).then(
        _district => setDistrict(_district)
      )
    }
  }, [accountData])

  useEffect(() => {
    if (district && accountData) {
      const memberships = accountData?.profile.academicGroupMemberships;
      const _isSchoolStaff = memberships.every(
        membership => membership.groupType === 'school'
      );
      if (_isSchoolStaff) {
        const schools = district.schools.filter(
          school => memberships.some(
            membership => membership.groupId === school.schoolId
          ) && ['staff','school-staff','district-staff'].includes(school.transitionalGroupType)
          && school.active
        )
        if (schools) {
          setSchoolStaff(schools)
        }

      }
      setIsSchoolStaff(_isSchoolStaff)
    }
  },[accountData, district] )
  
  const options = useMemo(() => {
    const _options: any[] = []
    
    if (district) {
      const memberships = accountData?.profile.academicGroupMemberships
      const _isSchoolStaff = memberships?.every(
        membership => membership.groupType === 'school'
      )
      if (_isSchoolStaff) {
        _options.push(
          ...district.schools.filter(
            school => (
              school.transitionalGroupType === 'school' &&
              school.active &&
              memberships?.some(
                membership => membership.groupId === school.schoolId
              )
            )
          )
        )
      } else {
        _options.push(
          ...district.schools.filter(
            school => school.transitionalGroupType === 'school' &&
            school.active
          )
        )
      }
    }

    return _options
  }, [accountData, district])

  const handleClose = (event: React.SyntheticEvent<EventTarget>) => {
    // This prevents both handleToggle and handleClose being called on profile button click
    const clickedNode = event.target

    if (flyerBoardButton.current?.contains(clickedNode as Node)) {
      return
    }

    setOpen(false)
  }

  const isFlyerBoardActive = flyerBoardRoutePattern.test(window.location.pathname)

  if (!district) {
    return <></>
  }

  return (
    <div className={cx(styles.wrapper, { [styles.activeDropdown]: isFlyerBoardActive })}>
      <Button
        ref={flyerBoardButton}
        aria-describedby={id}
        aria-owns={id}
        aria-haspopup="true"
        onClick={() => setOpen(_open => !_open)}
        className={styles.button}
        data-testid="flyer-boards-dropdown"
      >
        <img
          src={flyerBoardSvg}
          alt="Flyer board"
          className={styles.icon}
        />
        <span className={styles.buttonText}>Flyers</span>
      </Button>
      <Popper
        id={id}
        anchorEl={flyerBoardButton.current}
        open={open}
        className={styles.popper}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps}>
              <Paper className={styles.menuContainer}>
                <ul className={styles.menu}>
                  <DropdownOption
                    key={accountData?.profile.hierarchy.id}
                    name={'Staff Flyerboard'}
                    link={ getOptionLink({ 
                      academicType: 'district', 
                      academicId: parseInt(accountData?.profile.hierarchy.id || "0", 10), 
                      staff: isSchoolStaff ? 'school': 'district',
                      schools: isSchoolStaff ? schoolStaff : []
                    })}
                    additionalStyles={styles.staffHeader}
                  />
                  <li className={styles.parentFlyerboardHeader}>
                    {`Parent Flyerboard${options.length > 1 ? 's' : ''}`}
                  </li>
                  <li>
                    <a
                      className={cx(styles.link, styles.districtName)}
                      href={`${config.FLYERBOARD_URL}/all/districts/${district.districtId}`}
                      data-testid={district.name}
                      target='_blank'
                    >
                      {district.name}
                    </a>
                  </li>
                </ul>
                <ul className={styles.menu}>
                  {options.map(option => (
                    <DropdownOption
                      key={option.schoolId}
                      name={option.name}
                      link={ getOptionLink({academicType: 'school', academicId: option.schoolId })}
                    />
                  ))}
                </ul>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  )
}

type OptionLinkType = {
  academicType: 'school' | 'district',
  academicId: number,
  staff?: 'school' | 'district',
  schools?: any[]
}

const getOptionLink = ({ academicType, academicId, staff, schools = []  } : OptionLinkType) => {
  const staffParam = staff ? `?staff=${staff}` : ''
  const schoolParam = staff && schools.length > 0 && staff === 'school' ? '&schools=' + schools.map(s => s.schoolId).join(',') : '';
  return `${config.FLYERBOARD_URL}/all/${ academicType }s/${academicId}${ staffParam }${ schoolParam }`
}

const DropdownOption = ({ name, link, additionalStyles = '' }) => {
  const isActive = link === window.location.href;

  return (
    <li>
      <a
        href={link}
        className={cx(
          styles.link,
          { [styles.activeLink]: isActive },
          additionalStyles
        )}
        data-testid={name}
        target='_blank'
      >
        {name}
      </a>
    </li>
  );
};

const styles = {
  wrapper: css`
    border-right: 1px solid #e6e6e6;
  `,
  activeDropdown: css`
    border-bottom: 4px solid ${colors.leaf};
  `,
  popper: css`
    z-index: 999999;
    width: 240px;
  `,
  icon: css`
    height: 16px;
  `,
  button: css`
    padding: 0 1rem !important;
    border-radius: 0 !important;
    height: 100%;
    align-items: center !important;
    justify-content: space-between !important;
    text-transform: none !important;
  `,
  buttonText: css`
    padding: 0 0 0 5px;
    font-size: 13px;
    color: ${colors.prussian};
    `,
  districtName: css`
    display: block;
    font-size: 16px;
    color: ${colors.prussian};
    padding: 17px 15px;
  `,
  menuContainer: css`
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4) !important;
    max-height: 411px;
    overflow: auto;
    overflow-x: hidden;
  `,
  menu: css`
    list-style-type: none;
    margin: 0;
    padding: 0;
  `,
  staffHeader: css`
    font-size: 16px;
    border-bottom: 1px solid #e6e6e6;
    padding: 17px 16px;
  `,
parentFlyerboardHeader: css`
    font-size: 13px;
    color: #707070;
    padding: 5px 16px;
  `,
  link: css`
    display: block;
    color: ${colors.prussian};
    padding: 17px 15px 17px 32px;
    font-size: 13px;
    border-radius: 3px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
      background-color: #ededed;
      color: ${colors.prussian};
    }
  `,
  activeLink: css`
    border-left: 4px solid ${colors.leaf};
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.03);
  `
}

export default FlyerBoardDropDown
