import config from '../config/config'

export enum EventTopics {
  NotifySuccess = 'notify.success',
  NotifyInfo = 'notify.info',
  NotifyWarning = 'notify.warning',
  NotifyError = 'notify.error',
  AddCredits = 'deals.addCredits'
}

export enum FeatureFlags {
  PortalNav = 'portal_nav',
  OrgUploader = 'org_uploader'
}

const SUCCESS_TOPIC = 'notify.success'
const INFO_TOPIC = 'notify.info'
const WARNING_TOPIC = 'notify.warning'
const ERROR_TOPIC = 'notify.error'

const CONSTANTS = Object.freeze({
  APP_NAME: 'mapp-container-approval-center',
  APP_ROOT_ID: 'root',
  APP_SUB_APP_SCROLL_PARENT: 'sub-app-scroll-parent',
  APP_SUB_APP_ROOT_ID: 'root-sub-apps',
  APP_SIDEBAR_WIDTH: '208px',
  APP_BRANDBAR_HEIGHT: '56px'
})

export const APP_ROUTES = Object.freeze({
  account: "/account",
  account_info: config.ACCOUNT_SETTINGS_URL,
  account_transactions: "/account/transactions",
  dashboard: "/dashboard",
  campaigns: "/campaigns",
  contact_us: "/contact-us",
  deals: "/deals",
  deals_success: "/deals/success",
  faq: "/faq",
  foo: "/foo",
  postFlyer: "/campaigns/create-campaign/new/prepare/upload",
  approvals: {
    index: "/approvals",
    pending: "/approvals/pending",
    history: {
      myApprovals: "/approvals/my-approvals",
      allApprovals: "/approvals/all-approvals"
    }
  },
  help: config.REACT_APP_HELP_URL,
  pendingApprovals: "/pending-approvals",
  myApprovals: "/my-approvals",
  allApprovals: "/all-approvals",
  reporting: {
    index: "/reporting",
    allFlyers: "/reporting/all-flyers",
    myFlyers: "/reporting/my-flyers",
    report: "/reporting/:page/report/:flyerid"
  },
  staff: "/staff",
  schools: "/schools",
  schoolMgmt: '/mgmt',
  transactions: `${config.REACT_APP_PORTAL_BFF_URL}/transactions/history`,
  parents: "/parents",
  organizations: "/organizations",
  district: "/district",
  credits: {
    index: "/credits",
    cancelled: "/credits/cancelled",
    completed: "/credits/completed",
    history: "/credits/history"
  },
  communityFree: '/communityfree',
  distributionSchedule: '/distribution',
  distributionHistory: '/distribution/history',
})

export const NOTIFICATIONS = Object.freeze({
  errorKey: 'PJ_APP_ERROR',
  roleDenied:
    'Your assigned role does not have access to this area. Contact your district or school administrator for more information.',
  logoutFailed: 'Log out failed. Please try again.',
  removeCampaignSuccess: 'Your draft flyer has been removed.',
  removeCampaignError: 'Failed to remove draft flyer. Please try again.',
  removeFlyerSuccess: 'Your flyer has been removed.',
  removeFlyerError: 'Failed to remove flyer. Please try again.',
  removeFlyerWarning:
    'Flyer can only be removed in Flyer Reporting by the person who uploaded it. Please contact your District Admin to remove the flyer in All Approvals.',
  flyerDetailsInvalid:
    "Sorry, the flyer details you are looking for was moved or doesn't exist. Try one of the flyers in Flyer Reporting to find the flyer details you are looking for.",
  flyerReportInvalid:
    "Sorry, the flyer report you are looking for was moved or doesn't exist. Try one of the flyers in Flyer Reporting to find the flyer report you are looking for.",
  clipboardSuccess: 'Link has been copied to clipboard.',
  clipboardError: 'Failed to copy link to clipboard. Please try again.',
  paymentSuccess:
    'Your purchase is complete. View your transaction history for your most recent purchases.',
  paymentError:
    'Your payment did not go through due to a system error. Please try to submit payment again. If you need assistance contact us at (858) 997-2117.',
  genericError:
    'Something went wrong. Please try again. If you need assistance contact us at (858) 997-2117.',
  accountInfoSaveError: 'Could not update your information. Please try again.',
  accountInfoSaveSuccess: 'Your information has been updated.',
  accountInfoFileUploadSuccess: 'Your file has been uploaded.',
  accountInfoFileUploadError: 'Could not upload your file. Please try again.',
  accountInfoFileRemoveSuccess: 'Your file has been removed.',
  accountInfoFileRemoveError: 'Could not remove your file. Please try again.',
  accountInfoResetPasswordEmailSuccess:
    'A link to reset your password has been emailed to you.',
  accountInfoResetPasswordEmailError:
    'Failed to email a link to reset your password. Please try again.',
  passwordResetSendSuccess:
    'A link to reset your password has been emailed to you.',
  passwordResetSendFailure:
    'Failed to email a link to reset your password. Please try again.',
  switchAccountError: 'An error occurred trying to switch accounts.',
})

export const LOG_OUT_LOCAL_STORAGE_KEYS = Object.freeze({
  keys: [
    "pj_app_feature_flag_portal_nav_hydrated",
    "pj_app_feature_flag_org_uploader_hydrated",
    "peachjar_app_feature_flag_portal_nav",
    "peachjar_app_feature_flag_org_uploader"
  ]
})

export const SIDEBAR_WIDTH = {
  sm: "64px",
  md: "208px"
}

export const isProd = config.ENVIRONMENT === 'production'

export default CONSTANTS
