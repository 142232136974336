import React from 'react'

const DealsIcon = props => (
  <svg width="16px" height="16px" viewBox="0 0 12 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>Icon/Indicator/Discount</title>
      <desc>Created with Sketch.</desc>
      <defs>
          <path d="M14.2733333,7.72 L8.27333333,1.72 C8.03333333,1.48 7.7,1.33333333 7.33333333,1.33333333 L2.66666667,1.33333333 C1.93333333,1.33333333 1.33333333,1.93333333 1.33333333,2.66666667 L1.33333333,7.33333333 C1.33333333,7.7 1.48,8.03333333 1.72666667,8.28 L7.72666667,14.28 C7.96666667,14.52 8.3,14.6666667 8.66666667,14.6666667 C9.03333333,14.6666667 9.36666667,14.52 9.60666667,14.2733333 L14.2733333,9.60666667 C14.52,9.36666667 14.6666667,9.03333333 14.6666667,8.66666667 C14.6666667,8.3 14.5133333,7.96 14.2733333,7.72 L14.2733333,7.72 Z M3.66666667,4.66666667 C3.11333333,4.66666667 2.66666667,4.22 2.66666667,3.66666667 C2.66666667,3.11333333 3.11333333,2.66666667 3.66666667,2.66666667 C4.22,2.66666667 4.66666667,3.11333333 4.66666667,3.66666667 C4.66666667,4.22 4.22,4.66666667 3.66666667,4.66666667 L3.66666667,4.66666667 Z" id="path-1"></path>
      </defs>
      <g id="Org-Portal---Laura" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Navigation---Static-Dash" transform="translate(-17.000000, -276.000000)">
              <g id="Nav-Bar-/-Expanded-Copy" transform="translate(0.000000, 56.000000)">
                  <g id="nav---text" transform="translate(0.000000, 8.000000)">
                      <g id="nav-item---reporting" transform="translate(16.000000, 211.000000)">
                          <g id="Icon-/-Action-/-Reporting">
                              <g id="Icon/Indicator/Discount">
                                  <polygon id="Shape" points="0 0 16 0 16 16 0 16"></polygon>
                                  <mask id="mask-2" fill="white">
                                      <use xlinkHref="#path-1"></use>
                                  </mask>
                                  <use id="Shape" fill="#000000" fillRule="evenodd" xlinkHref="#path-1"></use>
                                  <g id="Color-/-Prussian" mask="url(#mask-2)" fill="#2D344B" fillRule="evenodd">
                                      <polygon id="#264653" points="0 0 16 0 16 16 0 16"></polygon>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
)

export default DealsIcon
