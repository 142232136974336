import React from 'react'

const ReportingIcon = props => (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>icon - reporting</title>
    <desc>Created with Sketch.</desc>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Nav-Bar-/-Collapsed" transform="translate(-23.000000, -173.000000)">
            <g id="Group" transform="translate(8.000000, 24.000000)">
                <g id="icon---reporting" transform="translate(13.000000, 147.000000)">
                    <polygon id="Shape" points="0 0 16 0 16 16 0 16"></polygon>
                    <path d="M6.66666667,13.3333333 L9.33333333,13.3333333 L9.33333333,2.66666667 L6.66666667,2.66666667 L6.66666667,13.3333333 Z M2.66666667,13.3333333 L5.33333333,13.3333333 L5.33333333,8 L2.66666667,8 L2.66666667,13.3333333 Z M10.6666667,6 L10.6666667,13.3333333 L13.3333333,13.3333333 L13.3333333,6 L10.6666667,6 Z" id="Shape" fill="#2D344B" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default ReportingIcon
