// @ts-nocheck
import React, { useState, useContext, useRef, useMemo } from 'react'
import { css, cx } from 'emotion'
import { colors } from '@peachjar/components'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import UserContext from '../../middleware/context/UserContext'
import SvgIcon from '../SvgIcon/SvgIcon'
import RolesEnum from '../../config/rolesEnum'
import config from '../../config/config'
import getLink from '../../utils/getLinks'

type Props = {
  roles: string[],
  hierarchy: {
    id: string;
    type: string;
  };
  topRoleEntity: number | string;
  hasMultipleSchoolsAdmin: boolean;
}

const { REACT_APP_PORTAL_BFF_URL } = config
const settingsSvgPath = 'M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z'

const SettingsDropdown = ({
  roles, 
  hierarchy, 
  topRoleEntity,
  hasMultipleSchoolsAdmin
}: Props) => {
  const { userData } = useContext(UserContext)
  const [open, toggleDropdown] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { entityId } = topRoleEntity;
  const settingsButton = useRef(null)
  const id = useMemo(() => open ? 'settings-popper' : null, [open])

  const { id: hierarchyId } = hierarchy
  const APPROVALS_URL = userData.profile.loginType ===  'internal' ? `${REACT_APP_PORTAL_BFF_URL}/settings/approvals?id=${hierarchyId}&type=district` : `${REACT_APP_PORTAL_BFF_URL}/settings/approvals`
  const optionsConfig = {
    DISTRICT_ADMIN: {
      items: [
        {
          label: 'District & Schools',
          link: `${REACT_APP_PORTAL_BFF_URL}/mgmt/district/${hierarchyId}`,
        },
        {
          label: 'Users & Roles',
          link: `${REACT_APP_PORTAL_BFF_URL}/mgmt/district/${hierarchyId}/users`,
        },
        {
          label: 'Distribution',
          link: `${REACT_APP_PORTAL_BFF_URL}/settings/distribution`,
        },
        {
          label: 'Approvals',
          link: APPROVALS_URL,
        },
        {
          label: 'Community',
          link: `${REACT_APP_PORTAL_BFF_URL}/mgmt/district/${hierarchyId}/community`,
        },
        {
          label: 'Parents',
          link: `${REACT_APP_PORTAL_BFF_URL}/mgmt/district/${hierarchyId}/parents`,
        },
      ],
    },
    SCHOOL_ADMIN: {
      items: [
        {
          label: hasMultipleSchoolsAdmin ? 'Schools' : 'School',
          link: hasMultipleSchoolsAdmin ? 
          `${REACT_APP_PORTAL_BFF_URL}/mgmt/district/${hierarchyId}`
          : `${REACT_APP_PORTAL_BFF_URL}/mgmt/school/${entityId}`,
         
        },
        {
          label: 'Users & Roles',
          link: `${REACT_APP_PORTAL_BFF_URL}/mgmt/district/${hierarchyId}/school/${entityId}/users`,
        },
      ],
    },
    ORG: {
      items: [
        {
          label: 'Organization',
          link: `${REACT_APP_PORTAL_BFF_URL}/settings/organization`,
        },
      ],
    },
  };

  const { location: { href: locationHref } } = window
  const activeLinks = [
    ...optionsConfig.DISTRICT_ADMIN.items.map(({ link }) => link),
    ...optionsConfig.SCHOOL_ADMIN.items.map(({ link }) => link),
    ...optionsConfig.ORG.items.map(({ link }) => link),
  ];

  // handle click of menu button
  const handleClick = (event: any) => {
    const { currentTarget } = event
    toggleDropdown(!open)
    setAnchorEl(currentTarget)
  }

  // handle closing of Popper when clicking outside of component
  const handleClose = (event: any) => {
    const { target } = event
    const clickedNode = target
    const settingsButtonRef = settingsButton.current
    // @ts-ignore
    const isNodeChildOfSettingsButton = settingsButtonRef && settingsButtonRef.contains(clickedNode)

    if (isNodeChildOfSettingsButton) {
      return
    }

    toggleDropdown(false)
  }

  const getRole = () => {
    const { DISTRICT_ADMIN, SCHOOL_ADMIN, ORG } = RolesEnum
    // district admins carry more weight, honor them first.
    if (roles.includes(DISTRICT_ADMIN)) {
      return DISTRICT_ADMIN
    }

    if (roles.includes(SCHOOL_ADMIN)) {
      return SCHOOL_ADMIN
    }

    if (roles.includes(ORG)) {
      return ORG;
    }

    // not school or district admin, return null
    return null
  }

  const userRole = getRole()

  if (userRole) {
    const isDropdownActive = activeLinks.includes(locationHref);

    return (
      <div
        className={cx('settings-menu-dropdown', {
          [cn.activeDropdown]: isDropdownActive,
        })}
        data-testid="settings-menu-dropdown"
      >
        <Button
          ref={settingsButton}
          aria-describedby={id}
          aria-owns={id}
          aria-haspopup="true"
          data-testid="click-settings-dropdown"
          onClick={handleClick}
          className={cn.settingsButton}
        >
          <div className={cn.iconContainer}>
            <SvgIcon className={cn.icon} path={settingsSvgPath} />
          </div>
          <span className={cn.buttonText}>Settings</span>
        </Button>
        <Popper
          id={id}
          anchorEl={anchorEl}
          open={open}
          className={cn.popper}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Grow {...TransitionProps }>
                <Paper className={cn.menuContainer}>
                  <ul className={`settings-menu ${cn.menu}`}>
                    { optionsConfig[userRole].items.map(
                      ({ label, link, linkType = 'external' }, index) => {
                        const isActive = link === locationHref
                        return (
                          <li key={index}>
                            {getLink(
                              label,
                              link,
                              linkType,
                              cx('settings-menu-item', cn.menuLink, {
                                [cn.activeLink]: isActive,
                              })
                            )}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </Paper>
              </Grow>
            </ClickAwayListener>
          )}
        </Popper>
      </div>
    )
  }

  return <></>
}

const cn = {
  activeDropdown: css`
    border-bottom: 4px solid ${colors.leaf};
  `,
  menuContainer: css`
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4) !important;
  `,
  menuButton: css`
    padding: 4px 8px;
    border-radius: 0;
  `,
  iconContainer: css`
    width: 1rem;
    height: 1.5rem;
    background-color: transparent;
  `,
  popper: css`
    width: 208px;
    z-index: 9000 !important;
  `,
  icon: css`
    margin: 3px 0 0 0;
    color: ${colors.prussian};
    font-size: 21px !important;
  `,
  settingsButton: css`
    padding: 0 1rem !important;
    border-radius: 0 !important;
    height: 100%;
    align-items: center !important;
    justify-content: space-between !important;
    text-transform: none !important;
  `,
  buttonText: css`
    font-family: Proxima Nova, Arial, Helvetica, sans-serif;
    color: ${colors.prussian};
    font-size: 13px;
    line-height: 1.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 0 0 5px;
  `,
  menu: css`
    list-style-type: none;
    padding: 8px;
    margin: 0;
  `,
  menuLink: css`
    display: block;
    color: ${colors.prussian};
    padding: 17px 15px;
    font-size: 13px;
    border-radius: 3px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      background-color: #ededed;
      color: ${colors.prussian};
    }
  `,
  activeLink: css`
    border-left: 4px solid ${colors.leaf};
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.03);
  `,
}

export default SettingsDropdown
