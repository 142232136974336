import React from 'react';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import { useQuery } from '@apollo/react-hooks';
import { Profile } from '../types/profile'
import gql from 'graphql-tag';
import client from '../middleware/apollo/accountsBFF.client';

export const GET_ACCOUNT_CONTEXT = gql`
  query getAccountContext {
    profile {
      id
      userId
      hierarchy {
        id
        type
      }
      scopes
      academicGroupMemberships {
        groupName
        groupType
        groupId
        hierarchyName
        hierarchyType
        hierarchyId
        roles
      }
      linkedAccounts {
        userId
        email
        hierarchy {
          id
          type
          name
        }
        scopes
      }
    }
  }
`

export type AccountContextApi = {
  data?: { profile: Profile }
  loading: boolean
};

export default function useAccountContext(): AccountContextApi {
  const { loading, data } = useQuery<{ profile: Profile }>(GET_ACCOUNT_CONTEXT, { client });

  return { data, loading }
}
