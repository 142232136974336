import React, { useEffect, useContext } from 'react'
import { components } from '@peachjar/components'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import Brandbar from '../Brandbar/Brandbar'
import Sidebar from '../Sidebar/Sidebar'
import subscribeToNotifications, { unsubscribeFromNotifications } from '../../events/subscriptions/notifications'
import constants from '../../config/constants'
import styles from './App.styles'
import UserContext from '../../middleware/context/UserContext'
import getFeatureFlags, { Flags } from '../../utils/flags'
import { setReduxFeatureFlags } from '../../middleware/redux/flags/flags.duck'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import ApprovalSettingsDraftNotification from '../ApprovalSettingsDraftNotification/ApprovalSettingsDraftNotification'
import DistributionSettingsNotification from '../DistributionSettingsNotification/DistributionSettingsNotification'

const {
  SubappContainer,
  Notifications: { NotificationContainer }
} = components

const App = ({ flags, setFeatureFlags }) => {
  // @ts-ignore
  const { userData: { profile: sodUser } } = useContext(UserContext)
  subscribeToNotifications()

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const flags: Flags = await getFeatureFlags()
      setFeatureFlags({ initialized: true, ...flags })
    }

    fetchFeatureFlags()

    return () => {
      unsubscribeFromNotifications()
    }
  }, [setFeatureFlags])

  if (!flags.initialized) {
    return <LoadingSpinner />
  }

  return (
    <div className={styles.app}>
      <NotificationContainer />
      <Brandbar sodUser={sodUser} processMode={false} flags={flags} />
      <div className={styles.contentWrapper}>
        <Sidebar permissions={sodUser.permission} flags={flags} />
        <div id={constants.APP_SUB_APP_SCROLL_PARENT} className={styles.columnContainer}>
          <SubappContainer>
            <ApprovalSettingsDraftNotification />
            <DistributionSettingsNotification />
            <div className={styles.subapps}>
              {/* micro apps mounted here */}
              <div id={constants.APP_SUB_APP_ROOT_ID}></div>
            </div>
          </SubappContainer>
        </div>
      </div>
    </div>
  )
}

type RootState = {
  flags: Flags
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  // @ts-ignore
  setFeatureFlags: (flags: Flags) => dispatch(setReduxFeatureFlags(flags)),
})

export default connect(
  (state: RootState) => ({ flags: state.flags }),
  mapDispatchToProps
)(App)
