import { generateSideNavConfig } from '../utils/generateAppConfig'
import config from '../config/config'
import { APP_ROUTES } from '../config/constants'
import PendingApprovalsBadge from '../components/PendingApprovalsBadge/PendingApprovalsBadge'
import {
  campaignsAllowedRoles,
  approvalsAllowedRoles,
  pendingApprovalsAllowedRoles,
  allApprovalsAllowedRoles,
  distributionAllowedRoles,
  distributionHistoryAllowedRoles
} from "../config/rolesEnum";

const {
  REACT_APP_PORTAL_APP_URL,
  REACT_APP_SIMPLE_SUBMISSION,
  REACT_APP_SUBMISSIONS
} = config

const Dashboard = {
  label: "Dashboard",
  path: `${REACT_APP_PORTAL_APP_URL}${APP_ROUTES.dashboard}`,
  externalLink: true,
  showInSideNav: true,
  isLive: true,
};

const Campaigns = {
  label: "Submissions",
  path: `${REACT_APP_SUBMISSIONS}`,
  externalLink: true,
  showInSideNav: true,
  isLive: true,
  allowedRoles: campaignsAllowedRoles
};

const SendFlyer = {
  label: "Post & Notify",
  path: REACT_APP_SIMPLE_SUBMISSION,
  externalLink: true,
  showInSideNav: true,
  isLive: true,
  allowedRoles: campaignsAllowedRoles
};

const Approvals = {
  label: "Approvals",
  path: APP_ROUTES.approvals.index,
  externalLink: false,
  showInSideNav: true,
  isLive: true,
  allowedRoles: approvalsAllowedRoles,
  exact: true
};

const PendingApprovals = {
  label: "Pending Approvals",
  path: APP_ROUTES.approvals.pending,
  externalLink: false,
  showInSideNav: true,
  isLive: true,
  BadgeComponent: PendingApprovalsBadge,
  allowedRoles: pendingApprovalsAllowedRoles,
  exact: true
};

const ApprovalsHistory = {
  label: "Approval History",
  path: APP_ROUTES.approvals.history.allApprovals,
  externalLink: false,
  showInSideNav: true,
  isLive: true,
  allowedRoles: allApprovalsAllowedRoles,
  exact: true
};

const Reporting = {
  label: "Reporting",
  path: APP_ROUTES.reporting.myFlyers,
  externalLink: true,
  showInSideNav: true,
  isLive: true,
};

const Deals = {
  label: "Deals",
  path: APP_ROUTES.deals,
  externalLink: true, // even though this is a micro app, it has its own template in Portal BFF (refer to peachjar-bff-portal/src/process-server.ts)
  showInSideNav: true,
  isLive: true
};

const DistributionSchedule = {
  label: "Distribution Schedule",
  path: APP_ROUTES.distributionSchedule,
  externalLink: false,
  showInSideNav: true,
  isLive: true,
  allowedRoles: distributionAllowedRoles,
  exact: true
};

const DistributionHistory = {
  label: "Distribution History",
  path: APP_ROUTES.distributionHistory,
  externalLink: false,
  showInSideNav: true,
  isLive: true,
  allowedRoles: distributionHistoryAllowedRoles,
  exact: true
};

const getSideNavConfig = (permissions) => {
  const { uploaderLevel, adminLevel, approverLevel } = permissions
  const sideNavConfig = [
    Dashboard,
  ]

  if (uploaderLevel === 'school' || uploaderLevel === 'district' || uploaderLevel === 'org') {
    sideNavConfig.push(Campaigns, SendFlyer)
  }

  if (adminLevel === 'school' || adminLevel === 'district' || approverLevel === 'school' || approverLevel === 'district') {
    sideNavConfig.push(Approvals)
  }

  if (approverLevel === 'school' || approverLevel === 'district') {
    sideNavConfig.push(PendingApprovals)
  }

  if (adminLevel === 'school' || adminLevel === 'district' || approverLevel === 'school' || approverLevel === 'district') {
    sideNavConfig.push(ApprovalsHistory)
  }

  sideNavConfig.push(
    DistributionSchedule,
    DistributionHistory
  )

  if (adminLevel === 'school' || adminLevel === 'district' || uploaderLevel === 'school' || uploaderLevel === 'district' || uploaderLevel === 'org') {
    sideNavConfig.push(Reporting)
  }

  if (uploaderLevel === 'org') {
    sideNavConfig.push(Deals)
  }

  const { sideNavSections } = generateSideNavConfig(sideNavConfig)

  return { sideNavSections }
}

export default getSideNavConfig
