import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import GlobalNotificationProvider from '@peachjar/ui/dist/lib/contexts/Notifications/GlobalNotificationProvider'
import DealsProvider from '../../middleware/context/DealsProvider'
import UserProvider from '../../middleware/context/UserProvider'
import apolloBffClient from '../../middleware/apollo/apolloBffClient'
import store from '../../middleware/redux/store/store'
import App from '../App/App'
import contentTheme from '../../themes/app.content.css.theme'
import constants from '../../config/constants'

declare global {
  interface Window { __PJ__: any }
}

const generateClassName = createGenerateClassName({
  productionPrefix: constants.APP_NAME
})

const AppWrapper = (props) => {
  const { logger, eventBroker } = props

  window.__PJ__ = window.__PJ__ || {}
  window.__PJ__.eventBroker = window.__PJ__.eventBroker || eventBroker
  window.__PJ__.logger = window.__PJ__.logger || logger

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloBffClient}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={contentTheme}>
            <GlobalNotificationProvider>
              <DealsProvider>
                <UserProvider>
                  <CssBaseline />
                  <App {...props} />
                </UserProvider>
              </DealsProvider>
            </GlobalNotificationProvider>
          </ThemeProvider>
        </StylesProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default AppWrapper
