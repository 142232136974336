function getConfig(domain) {
  switch (domain) {
    case 'portal-bff.peachjar.com':
      return {
        ENVIRONMENT: 'production',
        BFF_ACCOUNTS_GRAPHQL_URI: 'https://accounts-bff.peachjar.com/graphql',
        REACT_APP_ACCOUNTS_BFF_URL: 'https://accounts-bff.peachjar.com',
        REACT_APP_SIMPLE_SUBMISSION: 'https://portal-bff.peachjar.com/simple-submission',
        REACT_APP_SUBMISSIONS: 'https://portal-bff.peachjar.com/submissions',
        REACT_APP_DEALS_URL: 'https://portal-bff.peachjar.com/deals',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar.com',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar.com',
        REACT_APP_PORTAL_APP_URL: 'https://portal.peachjar.com',
        REACT_APP_PORTAL_BFF_URL: 'https://portal-bff.peachjar.com',
        REACT_APP_PEACHJAR_ACCOUNT_LINK:
          'https://accounts.peachjar.com',
        REACT_APP_PEACHJAR_ACCOUNT_LINK_ORGS:
          'https://portal-bff.peachjar.com/account/information',
        REACT_APP_SENTRY_API_KEY: '010c2ff9be6b461f8adc34bd08eb4ccf',
        REACT_APP_SENTRY_PROJECT_ID: '1299518',
        REACT_APP_GEOCORE_LOGIN_URL: 'https://accounts.peachjar.com',
        REACT_APP_PEACHJAR_HOMEPAGE: 'https://peachjar.com',
        REACT_APP_PARENT_APP_URL: 'https://app.peachjar.com',
        REACT_APP_GA_TRACKING_ID: 'UA-34619433-3',
        STRIPE_PUBLISHABLE_API_KEY: 'pk_live_2fPih1800WS1Iiwhdc4TfWFR',
        PAYPAL_PUBLISHABLE_CLIENT_ID:
          'AS4A3wWmouqFkoNRsap5UAa_Y3FMt7qoLk-jtu8CxK36YZ0QS_4X09hf9Wz3f7kaFiDcVV01W5nJAeTW',
        BFF_GRAPHQL_URI: 'https://portal-bff.peachjar.com/graphql',
        REACT_APP_LOGOUT_URL: 'https://accounts-bff.peachjar.com/auth/logout',
        REACT_APP_ALL_SESSIONS_DEVICES_LOGOUT_URL:
          'https://accounts-bff.peachjar.com/auth/sessions/logout',
        REACT_APP_GOOGLE_MAPS_API_KEY:
          'AIzaSyA7LhZJHRhqXLZB_oxYeqWvzg4h4REtpYs',
        REACT_APP_HELP_URL:
          'https://portal.peachjar.com/help',
        PASSWORD_RESET_URL:
          'https://portal-bff.peachjar.com/auth/platform/reset-my-password',
        OLD_HISTORY_URL: 'https://portal-bff.peachjar.com/transactions/legacy',
        DASHBOARD_URL: 'https://portal.peachjar.com/dashboard',
        REACT_APP_REPORTING_URL: 'https://portal-bff.peachjar.com/reporting/my-flyers',
        FLYERBOARD_URL: 'https://app.peachjar.com/flyers',
        DISTRICT_FLYERBOARD_URL:
        'https://portal-bff.peachjar.com/flyers/all/districts',
        ACCOUNT_SETTINGS_URL:
        'https://portal-bff.peachjar.com/account/information',
      }
    case 'portal-bff.peachjar-kauai.com':
      return {
        ENVIRONMENT: 'staging',
        BFF_ACCOUNTS_GRAPHQL_URI: 'https://accounts-bff.peachjar-kauai.com/graphql',          
        REACT_APP_ACCOUNTS_BFF_URL: 'https://accounts-bff.peachjar-kauai.com',
        REACT_APP_SIMPLE_SUBMISSION: 'https://portal-bff.peachjar-kauai.com/simple-submission',
        REACT_APP_SUBMISSIONS: 'https://portal-bff.peachjar-kauai.com/submissions',
        REACT_APP_DEALS_URL: 'https://portal-bff.peachjar-kauai.com/deals',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar-kauai.com',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar-kauai.com',
        REACT_APP_PORTAL_APP_URL: 'https://portal.peachjar-kauai.com',
        REACT_APP_PORTAL_BFF_URL: 'https://portal-bff.peachjar-kauai.com',
        REACT_APP_PEACHJAR_ACCOUNT_LINK:
          'https://accounts.peachjar-kauai.com',
        REACT_APP_PEACHJAR_ACCOUNT_LINK_ORGS:
          'https://portal-bff.peachjar-kauai.com/account/information',
        REACT_APP_SENTRY_API_KEY: '010c2ff9be6b461f8adc34bd08eb4ccf',
        REACT_APP_SENTRY_PROJECT_ID: '1299518',
        REACT_APP_GEOCORE_LOGIN_URL: 'https://accounts.peachjar-kauai.com',
        REACT_APP_PEACHJAR_HOMEPAGE: 'https://peachjar-kauai.com',
        REACT_APP_PARENT_APP_URL: 'https://app.peachjar.com',
        REACT_APP_GA_TRACKING_ID: 'UA-126963057-1',
        STRIPE_PUBLISHABLE_API_KEY: 'pk_test_GiDwCCPgFja8BYFetGdLMlix',
        PAYPAL_PUBLISHABLE_CLIENT_ID:
          'AdlwdVxsORf4sXdcyn8lgW1UPcBuvcjMCWNKE5oPVBEaU1sMBoDphOHTkV4CEloMHVxLFlTlp9QwPHga',
        BFF_GRAPHQL_URI: 'https://portal-bff.peachjar-kauai.com/graphql',
        REACT_APP_LOGOUT_URL:
          'https://accounts-bff.peachjar-kauai.com/auth/logout',
        REACT_APP_ALL_SESSIONS_DEVICES_LOGOUT_URL:
          'https://accounts-bff.peachjar-kauai.com/auth/sessions/logout',
        REACT_APP_GOOGLE_MAPS_API_KEY:
          'AIzaSyA7LhZJHRhqXLZB_oxYeqWvzg4h4REtpYs',
        REACT_APP_HELP_URL:
          'https://portal.peachjar-kauai.com/help',
        PASSWORD_RESET_URL:
          'https://portal-bff.peachjar-kauai.com/auth/platform/reset-my-password',
        OLD_HISTORY_URL:
          'https://portal-bff.peachjar-kauai.com/transactions/legacy',
        DASHBOARD_URL: 'https://portal.peachjar-kauai.com/dashboard',
        REACT_APP_REPORTING_URL: 'https://portal-bff.peachjar-kauai.com/reporting/my-flyers',
        FLYERBOARD_URL: 'https://app.peachjar-kauai.com/flyers',
        DISTRICT_FLYERBOARD_URL:
          'https://portal-bff.peachjar-kauai.com/flyers/all/districts',
        ACCOUNT_SETTINGS_URL:
          'https://portal-bff.peachjar-kauai.com/account/information',
      }
    default:
      return {
        ENVIRONMENT: 'development',
        BFF_ACCOUNTS_GRAPHQL_URI: 'https://accounts-bff.peachjar.net:10443/graphql',          
        REACT_APP_ACCOUNTS_BFF_URL: 'https://accounts-bff.peachjar.net:10443',
        REACT_APP_SIMPLE_SUBMISSION: 'https://simple-submission.peachjar.net:10443/simple-submission',
        REACT_APP_SUBMISSIONS: 'https://submissions.peachjar.net:10443',
        REACT_APP_DEALS_URL: 'https://deals.peachjar.net:10443',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar.net:10443',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar.net:10443',
        REACT_APP_PORTAL_APP_URL: 'https://portal.peachjar.net:10443',
        REACT_APP_PORTAL_BFF_URL: 'https://portal-bff.peachjar.net:10443',
        REACT_APP_PEACHJAR_ACCOUNT_LINK:
          'https://accounts.peachjar.net:10443',
        REACT_APP_PEACHJAR_ACCOUNT_LINK_ORGS:
          'https://accounts.peachjar.net:10443',
        REACT_APP_SENTRY_API_KEY: '010c2ff9be6b461f8adc34bd08eb4ccf',
        REACT_APP_SENTRY_PROJECT_ID: '1299518',
        REACT_APP_GEOCORE_LOGIN_URL: 'https://accounts.peachjar.net:10443',
        REACT_APP_PEACHJAR_HOMEPAGE: 'http://peachjar.local',
        REACT_APP_PARENT_APP_URL: 'https://app.peachjar.com',
        REACT_APP_GA_TRACKING_ID: '',
        STRIPE_PUBLISHABLE_API_KEY: 'pk_test_GiDwCCPgFja8BYFetGdLMlix',
        PAYPAL_PUBLISHABLE_CLIENT_ID:
          'AdlwdVxsORf4sXdcyn8lgW1UPcBuvcjMCWNKE5oPVBEaU1sMBoDphOHTkV4CEloMHVxLFlTlp9QwPHga',
        BFF_GRAPHQL_URI: 'https://portal-bff.peachjar.net:10443/graphql',
        REACT_APP_LOGOUT_URL:
          'https://accounts-bff.peachjar.net:10443/auth/logout',
        REACT_APP_ALL_SESSIONS_DEVICES_LOGOUT_URL:
          'https://accounts-bff.peachjar.net:10443/auth/sessions/logout',
        REACT_APP_GOOGLE_MAPS_API_KEY:
          'AIzaSyA7LhZJHRhqXLZB_oxYeqWvzg4h4REtpYs',
          REACT_APP_HELP_URL:
          'https://portal.peachjar.net:10443/help',
        PASSWORD_RESET_URL:
          'https://portal-bff.peachjar.net:10443/auth/platform/reset-my-password',
        OLD_HISTORY_URL:
          'https://portal-bff.peachjar.net:10443/transactions/legacy',
        DASHBOARD_URL: 'https://portal.peachjar.net:10443/dashboard',
        REACT_APP_REPORTING_URL: 'https://reporting.peachjar.net:10443/reporting/my-flyers',
        FLYERBOARD_URL: 'https://app.peachjar.net:10443/flyers',
        DISTRICT_FLYERBOARD_URL:
          'https://app.peachjar.net:10443/flyers/all/districts',
        ACCOUNT_SETTINGS_URL:
          'https://portal.peachjar.net:10443/account/information',
      }
  }
}

export default getConfig(window.location.hostname)
