import gql from 'graphql-tag'

const GET_USER = gql`
  query GET_USER {
    profile {
      id
      firstName
      lastName
      email
      loginType
      scopes
      hierarchy {
        id
        type
      }
      permission {
        approverLevel
        adminLevel
        uploaderLevel
      }
      featureFlags {
        portal_nav
        org_uploader
      }
      roles {
        roles
        forEntity {
          entityId
          entityType
        }
      }
    }
  }
`

export default GET_USER
