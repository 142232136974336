import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { colors } from '@peachjar/components'

const TabContentLoadingSpinner = ({ height = '420px' }) => (
  <div
    style={{
      display: 'flex',
      height,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress
      color="inherit"
      size="2rem"
      style={{ color: colors.leaf, width: '2rem' }}
    />
  </div>
)

export default TabContentLoadingSpinner
