import * as React from 'react'
import { cx, css } from 'emotion'
import { colors, components } from '@peachjar/components'
import { BREAKPOINTS } from '@peachjar/ui/dist/constants'
import { SIDEBAR_WIDTH } from '../../config/constants'

const { VerticalNav } = components

const SidebarDrawer: React.FC = ({  children }) => (
  <div className={cx(classNames.sidebar)}>
    <div className={classNames.sidebarNav}>
      <VerticalNav label="Main Site Navigation">{children}</VerticalNav>
    </div>
  </div>
)

const classNames = {
  sidebar: css`
    overflow-x: hidden;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    border-right: 1px solid ${colors.mercury};
    position: absolute;
    top: 56px;
    bottom: 0;
    z-index: 2;
    background-color: ${colors.platinum};
    width: ${SIDEBAR_WIDTH.md};

    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      li > a {
        padding: 1rem 1rem 1rem 0.5rem;
      }
    }

    @media screen and (max-width: 1380px) {
      width: ${SIDEBAR_WIDTH.sm};
      transition: width 0.3s ease-in-out;

      li > a > span > span:first-of-type {
        opacity: 0;
        transition-property: opacity;
        transition-delay: 0.3s;
      }
      
      &:hover {
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
        width: ${SIDEBAR_WIDTH.md};
        
        li > a > span > span:first-of-type {
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  `,
  sidebarNav: css`
    width: ${SIDEBAR_WIDTH.md};
  `
}

export default SidebarDrawer
