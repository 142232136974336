import {
  notifySuccess,
  notifyInfo,
  notifyWarning,
  notifyError
} from '../../utils/notifications'
import { EventTopics } from '../../config/constants'

const {
  NotifySuccess,
  NotifyInfo,
  NotifyWarning,
  NotifyError
} = EventTopics

const notificationsRegistry = []

export default function subscribeToNotifications() {
  const broker = window.__PJ__.eventBroker
  const logger = window.__PJ__.logger

  try {
    const subscribeSuccess = broker.subscribe({
      channel: '/',
      topic: NotifySuccess,
      callback: (data, envelope) => {
        notifySuccess(data.text)
      }
    })

    const subscribeInfo = broker.subscribe({
      channel: '/',
      topic: NotifyInfo,
      callback: (data, envelope) => {
        notifyInfo(data.text)
      }
    })

    const subscribeWarning = broker.subscribe({
      channel: '/',
      topic: NotifyWarning,
      callback: (data, envelope) => {
        notifyWarning(data.text)
      }
    })

    const subscribeError = broker.subscribe({
      channel: '/',
      topic: NotifyError,
      callback: (data, envelope) => {
        logger.debug(`${NotifyError} topic triggered...`)
        notifyError(data.text)
      }
    })

    notificationsRegistry.push(
      // @ts-ignore
      subscribeSuccess,
      subscribeInfo,
      subscribeWarning,
      subscribeError
    )
  } catch (e) {
    console.log('event error: ', e)
  }
}

export function unsubscribeFromNotifications(): void {
  notificationsRegistry.forEach(subscription => {
    // @ts-ignore
    subscription.unsubscribe()
  })
}
